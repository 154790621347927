import { ColoredPoint, PointsWithRenderDetails } from '@unpublished/scene'
import { Vector3 } from 'polliwog-types'

import { NavigationChoices } from './landmarking-workflow'
import { State } from './state'

export interface ViewModel<LandmarkId extends string, View> {
  numPages: number
  currentPage: {
    number: number
    title: string
    description: string
    help: string
    initialView: View
    isComplete: boolean
    landmarkNames: LandmarkId[]
    totalLandmarks: number
    numLandmarksSet: number
    guideImage: string
    kind: 'pre' | 'post'
  }
  navigationChoices: NavigationChoices
  isComplete: boolean
  isFinished: boolean
  scene: {
    points: PointsWithRenderDetails
  }
}

export function createViewModel<LandmarkId extends string, View>(
  state: State<LandmarkId, View>
): ViewModel<LandmarkId, View> {
  const {
    help,
    title,
    description,
    initialView,
    landmarkNames,
    referenceLandmarkNames,
    image,
    kind,
  } = state.workflow.page(state.currentPage).props

  const numLandmarksSet =
    Object.values(landmarkNames).filter(
      name => state.landmarks[name] !== undefined
    ).length + state.unassignedLandmarks.length

  let points: ColoredPoint[] = landmarkNames
    .filter(name => state.landmarks[name] !== undefined)
    .map(name => ({
      point: state.landmarks[name] as Vector3,
      color: state.clearingPoint === name ? 'red' : 'paleGreen',
      name,
    }))
  points = points.concat(
    state.unassignedLandmarks.map((point, index) => ({
      point,
      color: state.clearingUnassignedPoint === index ? 'red' : 'paleGreen',
      name: '',
    }))
  )
  if (referenceLandmarkNames) {
    points = points.concat(
      referenceLandmarkNames
        .filter(name => state.landmarks[name] !== undefined)
        .map(name => ({
          point: state.landmarks[name] as Vector3,
          color: 'darkGray',
          name,
        }))
    )
  }

  return {
    numPages: state.workflow.numPages,
    currentPage: {
      number: state.currentPage,
      title,
      description,
      help,
      landmarkNames,
      initialView,
      isComplete: state.pageIsComplete,
      totalLandmarks: landmarkNames.length,
      numLandmarksSet,
      guideImage: `/hand-landmarks/${image ?? landmarkNames[0]}.png`,
      kind,
    },
    navigationChoices: state.workflow.navigationChoicesForPage(
      state.currentPage
    ),
    isFinished: state.isFinished,
    isComplete: state.workflow.landmarksAreComplete(state.landmarks),
    scene: { points: { points, showPointLabels: false } },
  }
}
