import { ApolloClient, gql } from '@apollo/client'
import { AnnotationDelta } from '@unpublished/common-components'

import {
  GetAnnotationsQuery,
  GetAnnotationsQueryVariables,
  LoadAnnotationsQuery,
  LoadAnnotationsQueryVariables,
  UpdateAnnotationsMutation,
  UpdateAnnotationsMutationVariables,
} from '../../generated'

export async function loadGeometryAnnotations({
  apolloClient,
  geometryId,
}: {
  apolloClient: ApolloClient<object>
  geometryId: number
}): Promise<AnnotationDelta<string>[]> {
  const { data } = await apolloClient.query<
    LoadAnnotationsQuery,
    LoadAnnotationsQueryVariables
  >({
    query: gql`
      query LoadAnnotations($geometryId: Int!) {
        geometryById(id: $geometryId) {
          id
          geometryAnnotationsByGeometryId {
            nodes {
              id
              geometryAnnotationTypeByGeometryAnnotationTypeId {
                name
              }
              point
            }
          }
        }
      }
    `,
    variables: { geometryId },
    fetchPolicy: 'no-cache',
  })
  return data.geometryById.geometryAnnotationsByGeometryId.nodes.map(item => ({
    id: item.geometryAnnotationTypeByGeometryAnnotationTypeId.name,
    point: item.point as [number, number, number] | null,
  }))
}

export async function saveGeometryAnnotations({
  apolloClient,
  geometryId,
  deltas: inDeltas,
}: {
  apolloClient: ApolloClient<object>
  geometryId: number
  deltas: AnnotationDelta<string>[]
}): Promise<{
  committedDeltas: AnnotationDelta<string>[]
}> {
  const { data } = await apolloClient.query<
    GetAnnotationsQuery,
    GetAnnotationsQueryVariables
  >({
    query: gql`
      query GetAnnotations($geometryId: Int!) {
        geometryById(id: $geometryId) {
          id
          geometryAnnotationsByGeometryId {
            nodes {
              id
              geometryAnnotationTypeByGeometryAnnotationTypeId {
                name
              }
            }
          }
        }
      }
    `,
    variables: { geometryId },
  })

  const annotationIds = new Map<string, number>()
  data.geometryById.geometryAnnotationsByGeometryId.nodes.forEach(item =>
    annotationIds.set(
      item.geometryAnnotationTypeByGeometryAnnotationTypeId.name,
      item.id
    )
  )

  const committedDeltas = inDeltas.filter(item => annotationIds.has(item.id))

  if (committedDeltas.length > 0) {
    const outDeltas = committedDeltas.map(item => ({
      id: annotationIds.get(item.id) as number,
      point: item.point,
    }))

    await apolloClient.mutate<
      UpdateAnnotationsMutation,
      UpdateAnnotationsMutationVariables
    >({
      mutation: gql`
        mutation UpdateAnnotations(
          $input: mnUpdateGeometryAnnotationByIdInput!
        ) {
          mnUpdateGeometryAnnotationById(input: $input) {
            clientMutationId
            geometryAnnotation {
              geometryId
              id
              nodeId
              point
            }
          }
        }
      `,
      variables: { input: { mnGeometryAnnotationPatch: outDeltas } },
    })
  }

  return { committedDeltas }
}
