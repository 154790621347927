import { Output } from '@curvewise/armscyence-types-handy'
import { HandOrientation } from '@curvewise/common-types'

import { HandLandmarks } from './hand-landmarks'
import { computeHandOrientation } from './hand-orientation'

export type Phase =
  | 'empty'
  | 'preparing'
  | 'ready'
  | 'orientation'
  | 'landmarking'

export interface State {
  geometryId?: number
  phase: Phase
  changesHaveBeenSaved: boolean
  handyResult?: Output
  orientation?: HandOrientation
}

export const INITIAL_STATE: State = {
  phase: 'empty',
  changesHaveBeenSaved: false,
}

export type Action =
  | { type: 'choose-geometry'; geometryId: number }
  | { type: 'set-handy-result'; result: Output }
  | { type: 'start-orientation' }
  | { type: 'leave-orientation'; landmarks: Partial<HandLandmarks> }
  | { type: 'start-landmarking' }
  | { type: 'leave-landmarking' }
  | { type: 'note-changes-have-been-saved' }

export function reducer(state: State, action: Action): State {
  if (action.type === 'choose-geometry') {
    return {
      ...state,
      geometryId: action.geometryId,
      phase: 'preparing',
      handyResult: undefined,
      changesHaveBeenSaved: false,
    }
  } else if (action.type === 'set-handy-result') {
    return {
      ...state,
      phase: 'orientation',
      handyResult: action.result,
    }
  } else if (action.type === 'start-orientation') {
    if (state.phase === 'preparing') {
      throw Error('Expected to be ready before starting')
    }
    return { ...state, phase: 'orientation', orientation: undefined }
  } else if (action.type === 'leave-orientation') {
    if (state.phase !== 'orientation') {
      throw Error('Expected to be in orientation')
    }
    return {
      ...state,
      phase: 'ready',
      orientation: computeHandOrientation(action.landmarks),
    }
  } else if (action.type === 'start-landmarking') {
    if (state.orientation === undefined) {
      throw Error('Expected orientation to be completed')
    }
    return { ...state, phase: 'landmarking' }
  } else if (action.type === 'leave-landmarking') {
    if (state.phase !== 'landmarking') {
      throw Error('Expected to be in landmarking')
    }
    return { ...state, phase: 'ready' }
  } else if (action.type === 'note-changes-have-been-saved') {
    return { ...state, changesHaveBeenSaved: true }
  }
  return state
}
