import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'

import { App } from './app/app'
import { reportWebVitals } from './report-web-vitals'

const appElement = document.getElementById('root')
if (!appElement) {
  throw Error('Root element not found')
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  appElement
)

Modal.setAppElement(appElement)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
