import { HandOrientation, LengthUnits, Side } from '@curvewise/common-types'
import { Vector3 } from 'polliwog-types'
import * as THREE from 'three'

import { assertMeasuredHandDocument } from '../measurement-workflow/measured-hand-document'
import {
  GoldieMeshInfo,
  InlineMesh,
  MeasuredHandDocument,
} from '../measurement-workflow/measured-hand-document.schema'

function goldieMeshInfo({
  dataLayerUrl,
  geometryId,
}: {
  dataLayerUrl: string
  geometryId: number
}): GoldieMeshInfo {
  return {
    schema: 'curvewise.GoldieMeshInfo',
    schemaVersion: 1,
    dataLayerUrl,
    geometryId,
  }
}

// TODO: Preserve the original indexed geometry.
function serializeGeometry(geometry: THREE.BufferGeometry): InlineMesh {
  // TODO: Merge the vertices to reduce the vertex count and size. Needs a
  // Three.js upgrade.
  // THREE.BufferGeometryUtils.mergeVertices(geometry, 0)

  const numFaces = geometry.attributes.position.array.length / 3

  return {
    schema: 'curvewise.InlineMesh',
    schemaVersion: 1,
    vertices: Array.from(
      // TODO: Why is this cast needed? Seems like future versions of Three.js
      // will remove the need for it.
      (geometry.attributes.position.array as Float32Array).values()
    ),
    faces: Array.from(Array(numFaces).keys()),
    arity: 3,
  }
}

export function generateMeasuredHandDocument({
  dataLayerUrl,
  geometryId,
  geometry,
  side,
  units,
  orientation,
  landmarks,
}: {
  dataLayerUrl: string
  geometryId: number
  geometry: THREE.BufferGeometry
  side: Side
  units: LengthUnits
  orientation: HandOrientation
  landmarks: Partial<Record<string, Vector3>>
}): MeasuredHandDocument {
  const result: MeasuredHandDocument = {
    schema: 'curvewise.MeasurementDocument',
    schemaVersion: 1,
    kind: 'hand',
    side,
    mesh: [
      goldieMeshInfo({ dataLayerUrl, geometryId }),
      serializeGeometry(geometry),
    ],
    topology: 'topology-free',
    units,
    orientation,
    dependencyGraph: {
      schemaVersion: 1,
      inputs: {},
      intermediates: {},
      outputs: {},
    },
    computeNodes: {
      inputs: landmarks,
      intermediates: {},
      outputs: {},
    },
    regions: {
      inputs: [], // TODO: Fill these in.
      outputs: [],
    },
    inputAttributes: undefined,
  }
  assertMeasuredHandDocument(result)
  return result
}
