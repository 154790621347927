import { useEffect, useRef } from 'react'

function defaultIsEqual<State>(
  first: NonNullable<State>,
  second: NonNullable<State>
): boolean {
  return first === second
}

export function useStateChangeEffect<State>(
  cb: (prevState: State) => void,
  state: NonNullable<State>,
  {
    isEqual = defaultIsEqual,
  }: {
    isEqual?: (first: NonNullable<State>, second: NonNullable<State>) => boolean
  } = {}
): void {
  const prevState = useRef<NonNullable<State>>(
    undefined as unknown as NonNullable<State>
  )
  if (prevState.current === undefined) {
    prevState.current = state
  }

  useEffect(() => {
    if (!isEqual(state, prevState.current)) {
      const prevStateValue = prevState.current
      prevState.current = state
      cb(prevStateValue)
    }
  }, [state, cb, isEqual])
}
