import { Button, FlexColumn, FlexRow } from '@unpublished/common-components'
import React from 'react'
import styled, { css } from 'styled-components'

import { ReactComponent as Check } from '../check_circle.svg'
import { ReactComponent as Eye } from '../eye.svg'
import { ReactComponent as RightArrow } from '../right-arrow.svg'
import { ReactComponent as Target } from '../target.svg'
import { ReactComponent as XIcon } from '../x-circle.svg'
import { Action } from './state'
import { createViewModel } from './view-model'

const GREY_BORDER = css`
  border: 1px solid #c4c4c4cc;
`

const WorkflowPanelContainer = styled(FlexColumn)`
  margin: 2em 2em 0 0;
  row-gap: 20px;
  align-items: center;
  color: #666666;
  align-items: center;
`

const NavigationContainer = styled(FlexRow)`
  color: #999999;
  width: min-content;
  ${GREY_BORDER}
`

const WorkflowBodyContainer = styled(FlexColumn)`
  ${GREY_BORDER}
  width: 575px;
`

const NavigationTitle = styled(FlexColumn)`
  background: #ededed;
  border-right: 1px #c4c4c4cc;
  border-left: 1px #c4c4c4cc;
  width: 217px;
  align-items: center;
  justify-content: space-evenly;
  div:first-child {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.04em;
  }
  div:nth-child(2) {
    font-size: 18px;
    font-weight: 600;
  }
`

const GreyColumn = styled(FlexColumn)`
  background: #ededed;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
  * {
    padding: 5px;
  }
`

const TitleDiv = styled(GreyColumn)`
  min-height: 125px;
  div:first-child {
    font-size: 20px;
    font-weight: 600;
  }
`

const CheckboxOuterContainer = styled(FlexRow)`
  background: #fafafa;
  border-top: 1px solid #c4c4c4cc;
  border-bottom: 1px solid #c4c4c4cc;
  padding: 30px;
  justify-content: center;
`

const CheckboxInnerContainer = styled(FlexRow)`
  position: relative;
  width: max-content;
  align-items: center;
  justify-content: center;
`

const IconContainer = styled(FlexColumn)`
  ${GREY_BORDER}
  background: #ededed;
  width: 65px;
  height: 65px;
  align-items: center;
  justify-content: center;
  margin: 8px;
  position: relative;
`

const Guide = styled.img`
  height: 350px;
  object-fit: scale-down;
`

const StyledButton = styled.button`
  width: 70px;
  height: 70px;
  cursor: pointer;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    color: #303030;
  }
  &:disabled {
    cursor: default;
  }
`

const StyledXIcon = styled(XIcon)`
  position: absolute;
  z-index: 10;
  top: -10px;
  left: 52px;
  fill: #999999;
  cursor: pointer;
  &:hover {
    fill: red;
  }
`

const PointLabel = styled.div`
  position: absolute;
  z-index: 10;
  bottom: -20px;
`

const PrePostText = styled.div`
  position: absolute;
  left: -50px;
  top: 30px;
  text-transform: uppercase;
`

function NavigateButton<LandmarkId extends string, View>({
  direction,
  dispatch,
  disabled,
}: {
  direction: 'forward' | 'back'
  dispatch: React.Dispatch<Action<LandmarkId, View>>
  disabled: boolean
}): JSX.Element {
  return (
    <StyledButton
      onClick={() =>
        direction === 'forward'
          ? dispatch({ type: 'go-to-next-page' })
          : dispatch({ type: 'go-to-previous-page' })
      }
      disabled={disabled}
    >
      <RightArrow
        fill={disabled ? 'grey' : 'black'}
        transform={direction === 'back' ? 'rotate(180)' : undefined}
      />
    </StyledButton>
  )
}

function PointBox<LandmarkId extends string, View>({
  selected,
  kind,
  showLabel,
  label,
  landmarkIndex,
  dispatch,
}: {
  selected: boolean
  kind: 'pre' | 'post'
  showLabel: boolean
  label: string
  landmarkIndex: number
  dispatch: React.Dispatch<Action<LandmarkId, View>>
}): JSX.Element {
  return (
    <IconContainer>
      {selected ? <Check /> : kind === 'pre' ? <Target /> : <Eye />}
      {selected && (
        <StyledXIcon
          onMouseEnter={() =>
            dispatch({ type: 'preview-clear-point', index: landmarkIndex })
          }
          onMouseLeave={() => dispatch({ type: 'cancel-preview-clear-point' })}
          onClick={() =>
            dispatch({ type: 'clear-point', index: landmarkIndex })
          }
        />
      )}
      {showLabel && <PointLabel>{label}</PointLabel>}
    </IconContainer>
  )
}

export function LandmarkingWorkflowPanel<LandmarkId extends string, View>({
  viewModel,
  dispatch,
}: {
  viewModel: ReturnType<typeof createViewModel>
  dispatch: React.Dispatch<Action<LandmarkId, View>>
}): JSX.Element {
  return (
    <WorkflowPanelContainer>
      <NavigationContainer>
        <NavigateButton
          direction="back"
          dispatch={dispatch}
          disabled={!viewModel.navigationChoices.hasPrevious}
        />
        <NavigationTitle>
          <div>Landmarking</div>
          <div>
            Step {viewModel.currentPage.number} of {viewModel.numPages}
          </div>
        </NavigationTitle>
        <NavigateButton
          direction="forward"
          dispatch={dispatch}
          disabled={
            !viewModel.currentPage.isComplete ||
            !viewModel.navigationChoices.hasNext
          }
        />
      </NavigationContainer>
      <WorkflowBodyContainer>
        <TitleDiv>
          <div>{viewModel.currentPage.title}</div>
        </TitleDiv>
        <CheckboxOuterContainer>
          <CheckboxInnerContainer>
            {viewModel.currentPage.landmarkNames.map((landmark, i) => (
              <PointBox
                key={landmark}
                selected={viewModel.currentPage.numLandmarksSet >= i + 1}
                kind={viewModel.currentPage.kind}
                label={landmark}
                showLabel={viewModel.currentPage.isComplete}
                dispatch={dispatch}
                landmarkIndex={i}
              />
            ))}
            <PrePostText>{viewModel.currentPage.kind}</PrePostText>
          </CheckboxInnerContainer>
        </CheckboxOuterContainer>
        <GreyColumn>
          <Guide src={viewModel.currentPage.guideImage} />
          <div>
            {viewModel.currentPage.totalLandmarks > 1
              ? ' Pick in any order.'
              : ''}
          </div>
        </GreyColumn>
      </WorkflowBodyContainer>
      {viewModel.navigationChoices.hasNext ? (
        <Button
          disabled={!viewModel.currentPage.isComplete}
          onClick={() => dispatch({ type: 'go-to-next-page' })}
        >
          Next
        </Button>
      ) : (
        <Button
          disabled={!viewModel.isComplete}
          onClick={() => dispatch({ type: 'finish' })}
        >
          Finish
        </Button>
      )}
    </WorkflowPanelContainer>
  )
}
