import { CameraState } from '@curvewise/common-types'

export const PRESET_VIEWS: {
  width: number
  height: number
  views: CameraState[]
} = {
  width: 800,
  height: 600,
  views: [
    {
      position: [3.5494292462081813, 97.53310011174257, -0.06639449085055582],
      target: [4.414469442424411, 97.26076681355006, -1.8489600670989543],
      up: [0, 1, 0],
      zoom: 2.8328474206899474,
    },
    {
      position: [5.215496905578769, 97.39885764880388, -0.1868227218133425],
      target: [3.215496905578769, 97.39885764880388, -0.1868227218133426],
      up: [0, 1, 0],
      zoom: 2.8328474206899474,
    },
    {
      position: [3.553576489881375, 97.53310347662912, -0.29489494583883835],
      target: [4.352493651279948, 97.26077017843663, 1.5182696754568803],
      up: [0, 1, 0],
      zoom: 2.8328474206899474,
    },

    {
      position: [-1.1428984023248838, 117.23771461866146, 20.454759506135897],
      target: [-1.1598727778313993, 116.05874657591866, 22.070230404904237],
      up: [0, 1, 0],
      zoom: 8.189584766966803,
    },
  ],
}

// Adapt the view to the given height, width, or both.
export function adaptView({
  view,
  width,
  height,
}: {
  view: CameraState
  width?: number
  height?: number
}): CameraState {
  const scaleFactor = Math.min(
    ...([
      width ? width / PRESET_VIEWS.width : undefined,
      height ? height / PRESET_VIEWS.height : undefined,
    ].filter(Boolean) as number[])
  )

  return { ...view, zoom: scaleFactor * view.zoom }
}
