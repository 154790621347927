import {
  LengthUnits,
  MeasurementDocument,
  OrientedHand,
  Side,
} from '@curvewise/common-types'

import {
  InputNodes,
  IntermediateNodes,
  MachineContext,
  OutputNodes,
} from '../__generated__/dependencyGraph'
import { INITIAL_HAND_ORIENTATION } from '../state/hand-orientation'
import { HAND_REGIONS } from './content/hand-regions'
import { HAND_DEPENDENCY_GRAPH } from './hand-dependency-graph'
import { assertMeasuredHandDocument } from './measured-hand-document'
import { AnyMeshInfo, GoldieMeshInfo } from './measured-hand-document.schema'

// TODO: pass in side
const side: Side = 'left'

export type OldMeasuredHandDocument = MeasurementDocument<
  InputNodes,
  IntermediateNodes,
  OutputNodes,
  OrientedHand,
  GoldieMeshInfo,
  'topology-free'
>

export function computeMeasuredHandDocument({
  dataLayerUrl,
  selectedGeometryId,
  dependencyGraphContext,
  units,
}: {
  dataLayerUrl: string
  selectedGeometryId: number
  dependencyGraphContext: MachineContext
  units: LengthUnits
}): OldMeasuredHandDocument {
  const document: OldMeasuredHandDocument = {
    schema: 'curvewise.MeasurementDocument',
    schemaVersion: 1,
    kind: 'hand',
    side,
    orientation: INITIAL_HAND_ORIENTATION,
    mesh: {
      schema: 'curvewise.GoldieMeshInfo',
      schemaVersion: 1,
      dataLayerUrl,
      geometryId: selectedGeometryId,
    },
    topology: 'topology-free',
    units,
    dependencyGraph: HAND_DEPENDENCY_GRAPH,
    computeNodes: {
      inputs: Object.fromEntries(
        (Object.keys(HAND_DEPENDENCY_GRAPH.inputs) as Array<keyof InputNodes>)
          .map(input => [input, dependencyGraphContext[input]])
          .filter(([_, value]) => value !== null)
      ),
      intermediates: Object.fromEntries(
        (
          Object.keys(HAND_DEPENDENCY_GRAPH.intermediates) as Array<
            keyof IntermediateNodes
          >
        )
          .map(intermediate => [
            intermediate,
            dependencyGraphContext[intermediate],
          ])
          .filter(([_, value]) => value !== null)
      ),
      outputs: Object.fromEntries(
        (Object.keys(HAND_DEPENDENCY_GRAPH.outputs) as Array<keyof OutputNodes>)
          .map(output => [output, dependencyGraphContext[output]])
          .filter(([_, value]) => value !== null)
      ),
    },
    inputAttributes: undefined,
    regions: {
      inputs: HAND_REGIONS,
      outputs: [],
    },
  }
  assertMeasuredHandDocument(document)
  return document
}

function schemaMatches(
  first: { schema: string; schemaVersion: number },
  second: { schema: string; schemaVersion: number }
): boolean {
  return (
    first.schema === second.schema &&
    first.schemaVersion === second.schemaVersion
  )
}

export function findMeshInfo<MeshInfoType extends AnyMeshInfo>(
  meshInfo: AnyMeshInfo | AnyMeshInfo[],
  schemaData: {
    schema: MeshInfoType['schema']
    schemaVersion: MeshInfoType['schemaVersion']
  }
): MeshInfoType | undefined {
  if (Array.isArray(meshInfo)) {
    const matched = meshInfo.find(item => schemaMatches(item, schemaData))
    if (matched !== undefined) {
      return matched as MeshInfoType
    }
  } else if (schemaMatches(schemaData, meshInfo)) {
    return meshInfo as MeshInfoType
  }
  return undefined
}

export function findGoldieMeshInfo(
  meshInfo: AnyMeshInfo | AnyMeshInfo[]
): GoldieMeshInfo | undefined {
  return findMeshInfo<GoldieMeshInfo>(meshInfo, {
    schema: 'curvewise.GoldieMeshInfo',
    schemaVersion: 1,
  })
}
