import { HandLandmarks } from '../state/hand-landmarks'
import { HandView } from '../state/hand-orientation'
import {
  LandmarkingPage,
  LandmarkingWorkflow,
  LandmarkingWorkflowData,
} from './landmarking-workflow'

export type HandLandmarkingPage = LandmarkingPage<keyof HandLandmarks, HandView>
export type HandLandmarkingWorkflowData = LandmarkingWorkflowData<
  keyof HandLandmarks,
  HandView
>
export type HandLandmarkingWorkflow = LandmarkingWorkflow<
  keyof HandLandmarks,
  HandView
>

const orientationWorkflowData: HandLandmarkingWorkflowData = [
  {
    landmarkNames: ['mcp_d_3'],
    title: 'Metacarpal–phalangeal joint, dorsal, digit 3',
    help: 'Find the point on the metacarpal points/knuckles of digit 3 on the dorsal side of the hand at pre-landmarked location',
    description:
      'Prominent point on the metacarpal-phalangeal joint on digit 3 of the dorsal side of the hand',
    kind: 'pre',
    initialView: 'dorsum',
  },
  {
    landmarkNames: ['w_d'],
    title: 'Dorsal wrist crease center',
    description: 'Center of the dorsal wrist on the rubber band',
    help: 'Find the point at the center of the rubber band on the dorsal side of the wrist at pre-landmarked location',
    kind: 'pre',
    initialView: 'dorsum',
  },
  {
    landmarkNames: ['w_u'],
    title: 'Ulnar styloid wrist crease',
    description: 'Center of the ulnar wrist on the rubber band',
    help: 'Find the point at the center of the rubber band on the ulnar side of the wrist at pre-landmarked location',
    kind: 'pre',
    initialView: 'ulna',
  },
  {
    landmarkNames: ['w_r'],
    title: 'Radial styloid wrist crease',
    description: 'Center of the radial wrist on the rubber band',
    help: 'Find the point at the center of the rubber band on the radial side of the wrist at pre-landmarked location',
    kind: 'pre',
    initialView: 'radius',
  },
  {
    landmarkNames: ['mcp_r_2'],
    title: 'Side metacarpal joint, digit 2',
    description: 'Center of the side metacarpal joint protrusion for digit 2',
    help: 'Find the point at the side metacarpal joint on digit 2 at pre-landmarked location',
    kind: 'pre',
    initialView: 'radius',
  },
]

const landmarkingWorkflowData: HandLandmarkingWorkflowData = [
  {
    title: 'Palmar wrist crease center',
    help: 'Place the landmark at the center of the rubber band on the palmar side of the wrist, relying visually on the pre-landmark for location.',
    description: 'Center of the palmar wrist on the rubber band',
    landmarkNames: ['w_p'],
    kind: 'pre',
    initialView: 'palm',
  },
  {
    title: 'Metacarpal–phalangeal joint, dorsal',
    help: 'Find the point on the metacarpal points/knuckles on the dorsal side of the hand at pre-landmarked locations on the 1st, 2nd, 4th, and 5th digits. (The 3rd is already done.)',
    description: 'Center of the radial wrist on the rubber band',
    landmarkNames: ['mcp_d_1', 'mcp_d_2', 'mcp_d_4', 'mcp_d_5'],
    ordering: 'ulnar',
    image: 'mcp_d',
    kind: 'pre',
    referenceLandmarkNames: ['mcp_d_3'],
    initialView: 'dorsum',
  },
  {
    title: 'Proximal interphalangeal joint, dorsal',
    help: 'Find the point on the proximal interphalangeal joints on the dorsal side of the hand at pre-landmarked locations',
    description:
      'Prominent point on the proximal interphalangeal joint on the distal side of the hand from the 1st to 5th digits',
    landmarkNames: ['pip_d_1', 'pip_d_2', 'pip_d_3', 'pip_d_4', 'pip_d_5'],
    ordering: 'ulnar',
    image: 'pip_d',
    kind: 'pre',
    initialView: 'dorsum',
  },
  {
    title: 'Distal interphalangeal joint, dorsal',
    help: 'Find the points on the distal interphalangeal joint on the dorsal side of the hand at pre-landmarked locations',
    description:
      'Prominent point on the proximal interphalangeal joint on the dorsal side of the hand from the 2nd to 5th digits',
    landmarkNames: ['dip_d_2', 'dip_d_3', 'dip_d_4', 'dip_d_5'],
    ordering: 'ulnar',
    image: 'dip_d',
    kind: 'pre',
    initialView: 'dorsum',
  },
  {
    title: 'Finger tips',
    help: 'Find the points at the fingertips at pre-landmarked locations',
    description: 'Distal tips of all 5 digits',
    landmarkNames: ['ft_1', 'ft_2', 'ft_3', 'ft_4', 'ft_5'],
    ordering: 'ulnar',
    image: 'ft',
    kind: 'pre',
    initialView: 'tips',
  },
  {
    title: 'Crease point, metacarpal–phalangeal joint, palmar',
    help: 'Find the points at the root of the fingers on the palm of the hand at pre-landmarked locations',
    description:
      'Prominent point on the center of the metacarpal-phalangeal joint crease on the palmar side of the hand from the 1st to 5th digits',
    landmarkNames: ['fr_p_1', 'fr_p_2', 'fr_p_3', 'fr_p_4', 'fr_p_5'],
    ordering: 'ulnar',
    image: 'fr_p',
    kind: 'pre',
    initialView: 'palm',
  },
  {
    title: 'Crease point, proximal interphalangeal joint, palmar',
    help: 'Find the points at the proximal interphalangeal joints on the palm of the hand at pre-landmarked locations',
    description:
      'Prominent point on the center of the proximal interphalangeal joint crease on the palmar side of the hand from the 1st to 5th digits',
    landmarkNames: ['pip_p_1', 'pip_p_2', 'pip_p_3', 'pip_p_4', 'pip_p_5'],
    ordering: 'ulnar',
    image: 'pip_p',
    kind: 'pre',
    initialView: 'palm',
  },
  {
    title: 'Crease point, distal interphalangeal joint, palmar',
    help: 'Find the points on the distal interphalangeal joints on the palm of the hand at pre-landmarked locations',
    description:
      'Prominent point on the center on the distal interphalangeal joint crease on the palmar side of the hand from the 2nd digit to the 5th digit',
    landmarkNames: ['dip_p_2', 'dip_p_3', 'dip_p_4', 'dip_p_5'],
    ordering: 'ulnar',
    image: 'dip_p',
    kind: 'pre',
    initialView: 'palm',
  },
  {
    title: 'Side metacarpal joint, digit 5',
    help: 'Find the point at the side metacarpal joint on digit 5 at pre-landmarked location',
    description: 'Center of the side metacarpal joint protrusion for digit 5',
    landmarkNames: ['mcp_u_5'],
    kind: 'pre',
    initialView: 'ulna',
  },
  {
    title: 'Crease point on ulnar side of digit 5, metacarpal–phalangeal joint',
    help: 'Find the point at the finger crease on the ulnar side of digit 5 at pre-landmarked location',
    description:
      'Prominent point on the ulnar side of the metacarpal–phalangeal joint for digit 5',
    landmarkNames: ['fr_u_5'],
    kind: 'pre',
    initialView: 'ulna',
  },
  {
    title: 'Proximal interphalangeal joint radial side of digit 1',
    help: 'Find the point at the radial side of digit 1 (D1) at the pre-landmarked location',
    description:
      'Prominent point on the center of the proximal interphalangeal joint crease on the radial side of the hand for digit 1',
    landmarkNames: ['pip_r_1'],
    kind: 'pre',
    initialView: 'radius',
  },
  {
    title: 'Proximal interphalangeal joint ulnar side of digit 1',
    help: 'Find the point at the ulnar side of digit 1 (D1) at the pre-landmarked location',
    description:
      'Prominent point on the center of the proximal interphalangeal joint crease on the ulnar side of the hand for digit 1',
    landmarkNames: ['pip_u_1'],
    kind: 'pre',
    // TODO: Add this view.
    initialView: 'ulnarDigits',
  },
  {
    title: 'Proximal interphalangeal joint radial side of digit 2',
    help: 'Find the point at the radial side of digit 2 (D2) at the pre-landmarked location.',
    description:
      'Prominent point on the center of the proximal interphalangeal joint crease on the radial side of the hand for digit 2',
    landmarkNames: ['pip_r_2'],
    kind: 'pre',
    initialView: 'radius',
  },
  {
    title: 'Palmar webspace',
    help: 'Find the points at the palmar webspace at the center of digits 1–5 at pre-landmarked locations',
    description:
      'Bottom of the palmar side of the fingers at the webspace. Marked at the top of the web at the center of finger.',
    landmarkNames: ['ws_p_1', 'ws_p_2', 'ws_p_3', 'ws_p_4'],
    ordering: 'ulnar',
    image: 'ws_p',
    kind: 'post',
    initialView: 'palm',
  },
  {
    title: 'Finger root on the lateral side of digit 1',
    kind: 'post',
    help: 'Visually find the finger root on the radial side of digit 1.',
    description:
      'Finger root at the bottom of the thumb located at the radial side of the thumb',
    landmarkNames: ['fr_r_1'],
    initialView: 'radius',
  },
  {
    title: 'Tip of radial side of fingernail (onychodermal band) of digit 1',
    kind: 'post',
    help: 'Visually find the tip of radial side of fingernail (onychodermal band) of digit 1 (D1)',
    description:
      'Tip of radial side offingernail (onychodermalband)of digit 1 (D1)',
    landmarkNames: ['ob_d1'],
    initialView: 'radius',
  },
  {
    title: 'Tip of ulnar side of fingernail (onychodermal band) of digit 5',
    kind: 'post',
    help: 'Visually find the tip of ulnar side of fingernail (onychodermal band) of digit 5 (D5).',
    description:
      'Tip of ulnar side of fingernail (onychodermal band) of digit 5 (D5).',
    landmarkNames: ['ob_d5'],
    initialView: 'dorsum',
  },
  {
    title: 'Tip of radial side of fingernail (onychodermal band) of digit 2',
    kind: 'post',
    help: 'Visually find the tip of radial side of fingernail (onychoderm al band) of digit 2 (D2).',
    description:
      'Tip of radial side of fingernail (onychodermalband) of digit 2 (D2).',
    landmarkNames: ['ob_d2'],
    initialView: 'radiusSlightlyDorsal',
    // TODO: improve this one
  },
  {
    title: 'Proximal interphalangeal joint on the ulnar side of digit 2',
    help: 'Visually find the proximal interphalangeal joint at the ulnar side of digit 3 (D2).',
    description:
      'Prominent point on the center of the proximal interphalangeal joint crease on the ulnar side of the hand for digit 2.',
    landmarkNames: ['pip_u_2'],
    kind: 'post',
    initialView: 'ulnarDigits',
  },
  {
    title: 'Proximal interphalangeal joint on the radial side of digit 3',
    help: 'Visually find the proximal interphalangeal joint at the radial side of digit 3 (D3).',
    description:
      'Prominent point on the center of the proximal interphalangeal joint crease on the radial side of the hand for digit 3 (D3).',
    landmarkNames: ['pip_r_3'],
    kind: 'post',
    initialView: 'radialDigits',
  },
  {
    title: 'Proximal interphalangeal joint on the ulnar side of digit 3',
    kind: 'post',
    help: 'Visually find the proximal interphalangeal joint at the ulnar side of digit 3 (D3).',
    description:
      'Prominent point on the center of the proximal interphalangeal joint crease on the ulnar side of the hand for digit 3 (D3).',
    landmarkNames: ['pip_u_3'],
    initialView: 'ulnarDigits',
  },
]

export const WORKFLOWS: Record<string, HandLandmarkingWorkflow> = {
  orientation: new LandmarkingWorkflow(orientationWorkflowData),
  landmarking: new LandmarkingWorkflow(landmarkingWorkflowData),
}
