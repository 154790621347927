import { ApolloAuth0Provider } from '@unpublished/apollo-auth0'
import { createRender } from '@unpublished/auth0-helpers'
import { AppContainer, GlobalStyle } from '@unpublished/common-components'
import React, { useMemo } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { loadConfig } from './config'
import { Workspace } from './workspace'

export function App(): JSX.Element {
  const { dataLayerUrl, authEnabled, auth0 } = useMemo(loadConfig, [])

  const render = createRender(authEnabled)

  // NOTE: We are using Router here, even though it has only one Route, because
  // it fixes this login bug in ApolloAuth0Provider, although it is unclear why:
  // https://www.wrike.com/open.htm?id=934238867
  return (
    <BrowserRouter>
      <ApolloAuth0Provider
        withAuth0Credentials={authEnabled}
        url={dataLayerUrl}
        authConfig={auth0}
        scope="write:data"
      >
        <AppContainer>
          <GlobalStyle />
          <Routes>
            <Route path="/" element={render(Workspace)} />
          </Routes>
        </AppContainer>
      </ApolloAuth0Provider>
    </BrowserRouter>
  )
}
