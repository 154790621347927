import { useEffect, useRef } from 'react'

export function useInterval(
  cb: () => void,
  {
    intervalSeconds,
  }: {
    intervalSeconds: number
  }
): void {
  const onTick = useRef<() => void>()
  onTick.current = cb

  const timeout = useRef<NodeJS.Timeout>()
  useEffect(() => {
    if (timeout.current === undefined) {
      timeout.current = setInterval(() => {
        if (onTick.current) {
          onTick.current()
        }
      }, intervalSeconds * 1e3)
      return () => timeout.current && clearInterval(timeout.current)
    }
  }, [intervalSeconds])
}
