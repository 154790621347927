import { ApolloClient, gql, useApolloClient } from '@apollo/client'
import { useCallback } from 'react'

import {
  RefreshGeometryUrlQuery,
  RefreshGeometryUrlQueryVariables,
} from '../generated'

export async function refreshGeometryUrl({
  apolloClient,
  geometryId,
}: {
  apolloClient: ApolloClient<object>
  geometryId: number
}): Promise<string> {
  const { data } = await apolloClient.query<
    RefreshGeometryUrlQuery,
    RefreshGeometryUrlQueryVariables
  >({
    query: gql`
      query RefreshGeometryUrl($geometryId: Int!) {
        geometryById(id: $geometryId) {
          s3Key
          signedURL
        }
      }
    `,
    variables: { geometryId },
    fetchPolicy: 'no-cache',
  })

  if (!data.geometryById) {
    throw Error(`Unable to refresh signed URL for geometry ID ${geometryId}`)
  }

  return data.geometryById.signedURL
}

export function useRefreshGeometryUrl(): (
  geometryId: number
) => Promise<string> {
  const apolloClient = useApolloClient()

  return useCallback(
    (geometryId: number) => refreshGeometryUrl({ apolloClient, geometryId }),
    [apolloClient]
  )
}
