import { gql } from '@apollo/client'
import { HandOrientation, LengthUnits, Side } from '@curvewise/common-types'
import { PointsWithRenderDetails } from '@unpublished/scene'

import { GeometryDetailQuery } from '../generated'
import { createHandOrientationViewModel } from './hand-orientation-view-model'
import { UseLandmarkingWorkflow } from './landmarking-workflow/use-landmarking-workflow'
import { State } from './state'
import { HandLandmarks } from './state/hand-landmarks'
import { HandView } from './state/hand-orientation'

const ORIENTATION_PLACEHOLDER: HandOrientation = {
  distal: [0, 1, 0],
  dorsal: [0, 0, 1],
  radial: [-1, 0, 0],
}

export const GEOMETRY_DETAIL_QUERY = gql`
  query GeometryDetail($geometryId: Int!) {
    geometryById(id: $geometryId) {
      id
      s3Key
      signedURL
      textureS3Key
      textureSignedURL
      geometrySeriesByGeometrySeriesId {
        side
        subjectBySubjectId {
          name
          datasetByDatasetId {
            id
            units
          }
        }
      }
    }
  }
`

export interface Geometry {
  isPresent: boolean
  datasetId?: number
  subjectName?: string
  side?: Side
  units?: LengthUnits
}

function createGeometryDetailViewModel(data?: GeometryDetailQuery): Geometry {
  if (data) {
    const { side } = data.geometryById.geometrySeriesByGeometrySeriesId
    const { id: datasetId, units } =
      data.geometryById.geometrySeriesByGeometrySeriesId.subjectBySubjectId
        .datasetByDatasetId
    const { name: subjectName } =
      data.geometryById.geometrySeriesByGeometrySeriesId.subjectBySubjectId

    if (side === null) {
      throw Error('Expected side to be defined for a hand dataset')
    }

    return {
      isPresent: true,
      datasetId,
      subjectName,
      side: side.toLocaleLowerCase() as Side,
      units: units.toLocaleLowerCase() as LengthUnits,
    }
  } else {
    return { isPresent: false }
  }
}

export function createViewModel({
  state,
  geometryData,
  landmarkingWorkflow,
}: {
  state: State
  geometryData?: GeometryDetailQuery
  landmarkingWorkflow: UseLandmarkingWorkflow<keyof HandLandmarks, HandView>
}): {
  geometry: Geometry
  changeCount: number
  scene: {
    orientation: HandOrientation
    initialView: HandView
    points?: PointsWithRenderDetails
    isPicking: boolean
  }
} {
  let initialView: HandView
  switch (state.phase) {
    case 'orientation':
    case 'landmarking':
      initialView = landmarkingWorkflow.viewModel.currentPage.initialView
      break
    default:
      initialView = 'dorsum'
  }

  const orientation = state.orientation ?? state.handyResult?.orientation

  let points: PointsWithRenderDetails | undefined
  switch (state.phase) {
    case 'orientation':
    case 'landmarking':
      points = landmarkingWorkflow.viewModel.scene.points
      break
    default:
      points =
        orientation && state.handyResult?.dorsalApex
          ? createHandOrientationViewModel({
              orientation,
              dorsalApex:
                landmarkingWorkflow.state.landmarks.mcp_d_3 ??
                state.handyResult?.dorsalApex,
            })
          : undefined
  }

  let isPicking: boolean
  switch (state.phase) {
    case 'orientation':
    case 'landmarking':
      isPicking = !landmarkingWorkflow.viewModel.currentPage.isComplete
      break
    default:
      isPicking = false
  }

  return {
    geometry: createGeometryDetailViewModel(geometryData),
    changeCount: landmarkingWorkflow.state.deltas.length,
    scene: {
      orientation: orientation ?? ORIENTATION_PLACEHOLDER,
      initialView,
      points,
      isPicking,
    },
  }
}
