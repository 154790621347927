import { useQuery } from '@apollo/client'
import { CameraState } from '@curvewise/common-types'
import { Html } from '@react-three/drei'
import {
  Action,
  ActionsSidebar,
  Button,
  Overlay,
  usePoints,
} from '@unpublished/common-components'
import {
  Canvas,
  computeInitialView,
  MainObjectTransform,
  Nudging,
  Scene,
  useCanvas,
  useCanvasEvent,
  useControls,
  useMainObject,
  useObjLoader,
  useRotateAboutMainObject,
  useTextureLoader,
} from '@unpublished/scene'
import { downloadJsonContent, maybePluralize } from '@unpublished/victorinox'
import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import styled from 'styled-components'

import { GeometryDetailQuery, GeometryDetailQueryVariables } from '../generated'
import { Opener } from '../opener'
import { loadConfig } from './config'
import { WORKFLOWS } from './landmarking-workflow/hand-landmarking-workflows'
import { useLandmarkingWorkflow } from './landmarking-workflow/use-landmarking-workflow'
import { MeasuredHandDocument } from './measurement-workflow/measured-hand-document.schema'
import {
  useEnsureHandLandmarkAnnotationSchema,
  useLoad,
  useSave,
} from './persistence'
import { INITIAL_STATE, reducer } from './state'
import { generateMeasuredHandDocument } from './state/export'
import { HandAxisHelper } from './state/hand-orientation'
import { useHandyLambda } from './use-handy-lambda'
import { useInterval } from './use-interval'
import { useStateChangeEffect } from './use-state-change-effect'
import { createViewModel, GEOMETRY_DETAIL_QUERY } from './view-model'
import {
  DebugSegmentHelper,
  ReactDebugContext,
} from './work-platter/debug-work-platter'

const Main = styled.main`
  width: 100%;
  height: 100%;
`

const Row = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

const CanvasRegion = styled.div`
  position: relative;
  flex-grow: 1;
`

const MyActionsSidebar = styled(ActionsSidebar)`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 2em;
`

const CanvasSizedBox = styled.div`
  width: 423px;
  height: 378px;
`

const MenuBar = styled.nav`
  position: absolute;
  left: 20px;
  bottom: 10px;

  > * {
    margin: 10px;
    display: inline-block;
  }
`

export function Workspace(): JSX.Element {
  const { authEnabled, dataLayerUrl } = useMemo(loadConfig, [])

  const controls = useControls()
  const debugContext = useContext(ReactDebugContext)

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const [isOpening, setIsOpening] = useState(false)

  const landmarkingWorkflow = useLandmarkingWorkflow({
    initialWorkflow: WORKFLOWS.orientation,
  })

  const { error, data } = useQuery<
    GeometryDetailQuery,
    GeometryDetailQueryVariables
  >(GEOMETRY_DETAIL_QUERY, {
    variables: { geometryId: state.geometryId ?? 0 },
    skip: state.geometryId === undefined,
  })

  const viewModel = createViewModel({
    state,
    geometryData: data,
    landmarkingWorkflow,
  })

  const handyResult = useHandyLambda({
    endpointUrl:
      'https://71aig9fcwb.execute-api.us-east-1.amazonaws.com/handy-curvewise',
    geometryId: state.geometryId,
    side: viewModel.geometry.side,
  })
  useEffect(() => {
    if (state.phase === 'preparing' && handyResult) {
      dispatch({ type: 'set-handy-result', result: handyResult })
    }
  }, [state.phase, handyResult])

  const { body, errorMessage } = useObjLoader({
    s3Key: data?.geometryById.s3Key,
    signedURL: data?.geometryById.signedURL,
    enabled: Boolean(data),
  })

  const loadedTexture = useTextureLoader(
    data?.geometryById.textureSignedURL ?? undefined
  )

  useEnsureHandLandmarkAnnotationSchema({
    datasetId: viewModel.geometry.datasetId,
  })
  const load = useLoad({
    dispatch,
    dispatchLandmarkingAction: landmarkingWorkflow.dispatch,
  })
  const save = useSave({
    dispatch,
    dispatchLandmarkingAction: landmarkingWorkflow.dispatch,
  })
  useInterval(
    () => {
      if (state.geometryId && landmarkingWorkflow.state.deltas.length > 0) {
        save({
          geometryId: state.geometryId,
          deltas: landmarkingWorkflow.state.deltas,
        }).catch(console.error)
      }
    },
    { intervalSeconds: 5 }
  )

  // TODO: Fix these ill-thought-through property names...
  const { superiorAxis, anteriorAxis } = useMemo(
    () =>
      new HandAxisHelper(viewModel.scene.orientation).getView(
        viewModel.scene.initialView
      ),
    [viewModel.scene.orientation, viewModel.scene.initialView]
  )

  const canvas = useCanvas()
  const mainObject = useMainObject()

  function resetView(): void {
    if (controls && canvas && mainObject) {
      const view = computeInitialView({
        mainObject,
        viewerSize: canvas.getSize(),
      })
      controls.goToView({ ...view, up: [0, 1, 0] }, { enableTransition: false })
    }
  }

  useEffect(() => {
    if (controls && canvas && mainObject) {
      requestAnimationFrame(() => {
        controls.goToView({
          ...computeInitialView({
            mainObject,
            viewerSize: canvas.getSize(),
          }),
          up: [0, 1, 0],
        })
      })
    }
  }, [body, controls, canvas, mainObject, superiorAxis, anteriorAxis])

  useStateChangeEffect(() => {
    if (controls && canvas && mainObject) {
      requestAnimationFrame(() => {
        controls.goToView(
          {
            ...computeInitialView({ mainObject, viewerSize: canvas.getSize() }),
            up: [0, 1, 0],
            zoom,
          },
          { enableTransition: false }
        )
      })
    }
  }, viewModel.scene.initialView)

  const [zoom, setZoom] = useState(1)
  useCanvasEvent(
    'cameraDidUpdate',
    useCallback(({ zoom }: CameraState) => {
      setZoom(zoom)
    }, [])
  )
  useRotateAboutMainObject()
  const pointRadiusCm = 3.5 / zoom

  const [nudging] = useState<Nudging>()

  const { onMouseMove, onDoubleClick, lastHoveredPointOnMesh } =
    usePoints<string>({
      nudging,
      selectedAnnotationId: 'unused',
      dispatch: action => {
        if (
          viewModel.scene.isPicking &&
          action.type === 'update-delta' &&
          action.point !== null
        ) {
          landmarkingWorkflow.dispatch({
            type: 'set-point',
            point: action.point,
            radial: state.orientation?.radial,
          })
        }
      },
    })

  useStateChangeEffect(() => {
    if (state.phase === 'ready' && controls && canvas && mainObject) {
      const view = computeInitialView({
        mainObject,
        viewerSize: canvas.getSize(),
      })
      controls.goToView({ ...view, up: [0, 1, 0] }, { enableTransition: false })
    }
  }, state.phase)

  // TODO: Pass down dispatch instead.
  useEffect(() => {
    if (landmarkingWorkflow.viewModel.isFinished) {
      switch (state.phase) {
        case 'orientation':
          dispatch({
            type: 'leave-orientation',
            landmarks: landmarkingWorkflow.state.landmarks,
          })
          landmarkingWorkflow.dispatch({
            type: 'set-workflow',
            workflow: WORKFLOWS.landmarking,
          })
          dispatch({ type: 'start-landmarking' })
          break
        case 'landmarking':
          dispatch({ type: 'leave-landmarking' })
          break
      }
    }
  }, [
    state.phase,
    landmarkingWorkflow,
    landmarkingWorkflow.dispatch,
    landmarkingWorkflow.state.landmarks,
    landmarkingWorkflow.viewModel.isFinished,
  ])

  return (
    <Main>
      <Row>
        <CanvasRegion>
          <Overlay wantPointerEvents>
            <Canvas
              onMouseMove={onMouseMove}
              onDoubleClick={onDoubleClick}
              controlKind="orbit"
              render={() => (
                <Suspense
                  fallback={
                    <Html center={true}>
                      <CanvasSizedBox>
                        <div>Loading&hellip;</div>
                      </CanvasSizedBox>
                    </Html>
                  }
                >
                  {body && (
                    <Scene
                      body={body}
                      units={viewModel.geometry.units}
                      loadedTexture={loadedTexture}
                      cursorPosition={
                        viewModel.scene.isPicking
                          ? lastHoveredPointOnMesh
                          : undefined
                      }
                      pickPointRadiusCm={pointRadiusCm}
                      pointsWithRenderDetails={viewModel.scene.points}
                      tapeWidth={1}
                      lightingIntensity={1}
                      tapeColor="Ruby red"
                      flatShading={false}
                      anteriorAxis={anteriorAxis}
                      superiorAxis={superiorAxis}
                    />
                  )}
                  {debugContext.segmentHelperDebugInfo && body && (
                    <MainObjectTransform
                      mainObjectGeometry={body}
                      units={viewModel.geometry.units}
                    >
                      <DebugSegmentHelper
                        debugInfo={debugContext.segmentHelperDebugInfo}
                        pointRadiusCm={pointRadiusCm}
                        units={viewModel.geometry.units}
                      />
                    </MainObjectTransform>
                  )}
                </Suspense>
              )}
            />
          </Overlay>
          <MyActionsSidebar>
            <Action
              disabled={
                !['ready', 'landmarking', 'orientation'].includes(state.phase)
              }
              isActive={state.phase === 'orientation'}
              onClick={() => {
                if (state.phase === 'orientation') {
                  dispatch({
                    type: 'leave-orientation',
                    landmarks: landmarkingWorkflow.state.landmarks,
                  })
                } else {
                  landmarkingWorkflow.dispatch({
                    type: 'set-workflow',
                    workflow: WORKFLOWS.orientation,
                  })
                  dispatch({ type: 'start-orientation' })
                }
              }}
            >
              <span>Orient</span>
            </Action>
            <Action
              disabled={!state.orientation}
              isActive={state.phase === 'landmarking'}
              onClick={() => {
                if (state.phase === 'landmarking') {
                  dispatch({ type: 'leave-landmarking' })
                } else {
                  landmarkingWorkflow.dispatch({
                    type: 'set-workflow',
                    workflow: WORKFLOWS.landmarking,
                  })
                  dispatch({ type: 'start-landmarking' })
                }
              }}
            >
              <span>Landmark</span>
            </Action>
            <Action onClick={() => resetView()}>Reset view</Action>
          </MyActionsSidebar>
        </CanvasRegion>
        {(state.phase === 'orientation' || state.phase === 'landmarking') &&
          landmarkingWorkflow.renderPanel()}
      </Row>
      <div>
        {error && error.message}
        {errorMessage}
      </div>
      <MenuBar>
        <h1>Curve Lab</h1>
        <Button onClick={() => setIsOpening(true)}>Open</Button>
        <Button
          disabled={viewModel.changeCount === 0}
          onClick={() => {
            if (!state.geometryId) {
              throw Error('Expected geometryId to be set')
            }
            save({
              geometryId: state.geometryId,
              deltas: landmarkingWorkflow.state.deltas,
            }).catch(console.error)
          }}
        >
          Save
        </Button>
        <Button
          disabled={
            viewModel.changeCount > 0 ||
            !(
              state.geometryId !== undefined &&
              viewModel.geometry.subjectName !== undefined &&
              viewModel.geometry.side !== undefined &&
              viewModel.geometry.units !== undefined &&
              body !== undefined
            )
          }
          onClick={() => {
            if (
              state.geometryId !== undefined &&
              viewModel.geometry.subjectName !== undefined &&
              viewModel.geometry.side !== undefined &&
              viewModel.geometry.units !== undefined &&
              body
            ) {
              const document = generateMeasuredHandDocument({
                dataLayerUrl,
                geometryId: state.geometryId,
                side: viewModel.geometry.side,
                units: viewModel.geometry.units,
                geometry: body,
                orientation: viewModel.scene.orientation,
                landmarks: landmarkingWorkflow.state.landmarks,
              })
              downloadJsonContent({
                data: document,
                filename: `${viewModel.geometry.subjectName}.json`,
              })
            }
          }}
        >
          Export
        </Button>
        {viewModel.changeCount > 0 && (
          <p>
            {viewModel.changeCount}{' '}
            {maybePluralize('unsaved change', viewModel.changeCount)}
          </p>
        )}
        {viewModel.changeCount === 0 && state.changesHaveBeenSaved && (
          <p>All changes saved</p>
        )}
      </MenuBar>
      <Opener
        authEnabled={authEnabled}
        dataLayerUrl={dataLayerUrl}
        isOpen={isOpening}
        handleLoad={(geometryId: number, document?: MeasuredHandDocument) => {
          load({ geometryId }).catch(console.error)
          setIsOpening(false)
        }}
        onRequestClose={() => {
          setIsOpening(false)
        }}
      />
    </Main>
  )
}
