import { CameraState, Region } from '@curvewise/common-types'

import { InputNodes } from '../../__generated__/dependencyGraph'

export type HandRegion = Region<keyof InputNodes>
export type HandRegionWithViews = HandRegion & {
  view: CameraState
}

export const HAND_REGIONS: HandRegion[] = [
  {
    name: 'hand_planes',
    controlPoints: ['wrist_plane', 'palm_plane'],
    isOrdered: true,
  },
  {
    name: 'back_of_hand',
    controlPoints: [
      'finger_tip_2',
      'finger_tip_3',
      'finger_tip_4',
      'finger_tip_5',
      'dorsal_interdigital_2_3',
      'dorsal_interdigital_3_4',
      'dorsal_interdigital_4_5',
    ],
    isOrdered: true,
  },
  {
    name: 'radial_border',
    controlPoints: [
      'metacarpal_phalangeal_crease_2',
      'radial_palm_crease',
      'dorsal_interdigital_1_2',
      'finger_tip_1',
      'proximal_interphalangeal_crease_1',
      'metacarpal_phalangeal_crease_1',
    ],
    isOrdered: true,
  },
  {
    name: 'ulnar_border',
    controlPoints: [
      'distal_interphalangeal_crease_5',
      'proximal_interphalangeal_crease_5',
      'metacarpal_phalangeal_crease_5',
      'ulnar_palm_crease',
    ],
    isOrdered: true,
  },
]

const handRegionViews: Record<string, CameraState> = {
  hand_planes: {
    position: [10.698150105204821, 395.05488279179133, 4.331753537572779],
    target: [12.551190436390687, 394.4648962675452, 3.8646802754322915],
    up: [0, 1, 0],
    zoom: 2.5924251728853904,
  },
  back_of_hand: {
    position: [10.929493522881247, 395.72914403219966, -46.663310406822106],
    target: [10.929493146905218, 393.7291440322007, -46.663312371029185],
    up: [0, 1, 0],
    zoom: 3.5813561890310193,
  },
  radial_border: {
    position: [-3.3828847409673006, 382.4476354854492, -10.625068548965112],
    target: [-1.4610348935729627, 382.14411187077684, -11.088065934925957],
    up: [0, 1, 0],
    zoom: 4.4651495977173035,
  },
  ulnar_border: {
    position: [14.183525668144695, 395.6453348030965, -38.651633295303014],
    target: [12.262233870859792, 395.2384247351596, -39.02986870651849],
    up: [0, 1, 0],
    zoom: 6.085327188572707,
  },
}

export const HAND_REGIONS_WITH_VIEWS: HandRegionWithViews[] = HAND_REGIONS.map(
  region => ({
    ...region,
    view: handRegionViews[region.name],
  })
)
