import { computeCentroidFromThreeMesh } from '@unpublished/four'
import { useEffect, useMemo } from 'react'

import {
  useControls,
  useMainObject,
  useMainObjectGeometry,
} from '../canvas/canvas-context'

export function useRotateAboutMainObject(): void {
  const mainObject = useMainObject()
  const mainObjectGeometry = useMainObjectGeometry()

  // TODO: Try to rework this so it correctly reacts to changes to the main
  // object transform.

  // const [sentinel, setSentinel] = useState({})
  // const previousMatrixWorld = useRef<THREE.Matrix4>()
  // if (previousMatrixWorld.current === undefined) {
  //   previousMatrixWorld.current = new THREE.Matrix4()
  // }

  // if (
  //   mainObject &&
  //   !previousMatrixWorld.current.equals(mainObject.matrixWorld)
  // ) {
  //   console.log('matrices differ in userotateaboutmainobject')
  //   previousMatrixWorld.current.copy(mainObject.matrixWorld)
  //   setSentinel({})
  // }

  const centroid = useMemo(() => {
    // console.log('recomputing centroid in userotateaboutmainobject')
    if (mainObject && mainObjectGeometry) {
      return computeCentroidFromThreeMesh(mainObject)
    }
    // FIXME: Technically this should update when the scene transforms have
    // changed, too.
  }, [mainObject, mainObjectGeometry])

  const controls = useControls()
  useEffect(() => {
    if (controls && centroid) {
      controls.setRotationTarget(centroid)
    }
  }, [controls, centroid])
}
