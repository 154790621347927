import { useApolloClient } from '@apollo/client'
import { AnnotationDelta } from '@unpublished/common-components'
import React, { useCallback } from 'react'

import { WORKFLOWS } from '../landmarking-workflow/hand-landmarking-workflows'
import { Action as LandmarkingAction } from '../landmarking-workflow/state'
import { Action } from '../state'
import { HandLandmarks } from '../state/hand-landmarks'
import { HandView } from '../state/hand-orientation'
import { useEnsureDatasetAnnotationSchema } from './dataset-annotation-schema'
import {
  loadGeometryAnnotations,
  saveGeometryAnnotations,
} from './geometry-annotations'

const ALL_LANDMARK_NAMES = WORKFLOWS.orientation.allLandmarkNames.concat(
  WORKFLOWS.landmarking.allLandmarkNames
)

export function useEnsureHandLandmarkAnnotationSchema({
  datasetId,
}: {
  datasetId?: number
}): ReturnType<typeof useEnsureDatasetAnnotationSchema> {
  return useEnsureDatasetAnnotationSchema({
    datasetId,
    names: ALL_LANDMARK_NAMES,
  })
}

export function useLoad({
  dispatch,
  dispatchLandmarkingAction,
}: {
  dispatch: React.Dispatch<Action>
  dispatchLandmarkingAction: React.Dispatch<
    LandmarkingAction<keyof HandLandmarks, HandView>
  >
}): ({ geometryId }: { geometryId: number }) => Promise<void> {
  const apolloClient = useApolloClient()

  return useCallback(
    async ({ geometryId }) => {
      const annotations = await loadGeometryAnnotations({
        apolloClient,
        geometryId,
      })

      const landmarks: Partial<HandLandmarks> = {}
      annotations.forEach(item => {
        if (
          item.point !== null &&
          ALL_LANDMARK_NAMES.includes(item.id as keyof HandLandmarks)
        ) {
          landmarks[item.id as keyof HandLandmarks] = item.point
        }
      })

      dispatchLandmarkingAction({ type: 'load', landmarks })

      dispatch({ type: 'choose-geometry', geometryId })
    },
    [apolloClient, dispatch, dispatchLandmarkingAction]
  )
}

export function useSave({
  dispatch,
  dispatchLandmarkingAction,
}: {
  dispatch: React.Dispatch<Action>
  dispatchLandmarkingAction: React.Dispatch<
    LandmarkingAction<keyof HandLandmarks, HandView>
  >
}): ({
  geometryId,
  deltas,
}: {
  geometryId: number
  deltas: AnnotationDelta<keyof HandLandmarks>[]
}) => Promise<void> {
  const apolloClient = useApolloClient()

  return useCallback(
    async ({ geometryId, deltas }) => {
      const { committedDeltas } = await saveGeometryAnnotations({
        apolloClient,
        geometryId,
        deltas,
      })
      dispatch({ type: 'note-changes-have-been-saved' })
      dispatchLandmarkingAction({
        type: 'discard-saved-deltas',
        deltas: committedDeltas as AnnotationDelta<keyof HandLandmarks>[],
      })
    },
    [apolloClient, dispatch, dispatchLandmarkingAction]
  )
}
