import { KnownBodyTopology } from '@curvewise/common-types'
import { getFaceCount } from '@unpublished/four'
import * as THREE from 'three'

import {
  meshcapadeSm4FaceGroupAsset,
  mpiiHumanShapeFaceGroupAsset,
} from './mesh-prep'

// The OBJ loader flattens vertices, so `vertices.length` is always
// `3 * faces.length` and `vertices.faces` is `[[0, 1, 2], [3, 4, 5]]...`. So
// the only thing we can do is count the faces.

// function headMatches(faces: THREE.Face3[], expected: Vector3Values[]) {
//   debugger
//   return expected.every((face, index) => {
//     const [expectedA, expectedB, expectedC] = face
//     const { a, b, c } = faces[index]
//     return a === expectedA && b === expectedB && c === expectedC
//   })
// }

// function tailMatches(faces: THREE.Face3[], expected: Vector3Values[]) {
//   return expected
//     .slice()
//     .reverse()
//     .every((face, index) => {
//       const [expectedA, expectedB, expectedC] = face
//       const { a, b, c } = faces[faces.length - 1 - index]
//       return a === expectedA && b === expectedB && c === expectedC
//     })
// }

// const sm4Mid = {
//   head: [
//     [6891, 2, 6892],
//     [6891, 6892, 20679],
//   ] as Vector3Values[],
//   tail: [
//     [19017, 6310, 20678],
//     [19017, 20678, 27578],
//   ] as Vector3Values[],
// }

export function detectTopology(
  geometry: THREE.BufferGeometry
): KnownBodyTopology | undefined {
  const numFaces = getFaceCount(geometry)

  if (numFaces === 55152) {
    return 'meshcapade-sm4-mid'
  } else if (numFaces === 12894) {
    return 'mpii-human-shape-1.0'
  } else {
    return undefined
  }
}

export async function bodyPartNamesForTopology(
  topology: KnownBodyTopology
): Promise<ReadonlyArray<string>> {
  if (topology === 'meshcapade-sm4-mid') {
    return (await meshcapadeSm4FaceGroupAsset.read()).groups.map(g => g.name)
  } else if (topology === 'mpii-human-shape-1.0') {
    return (await mpiiHumanShapeFaceGroupAsset.read()).groups.map(g => g.name)
  } else {
    return []
  }
}
