import { CameraState } from '@curvewise/common-types'
import {
  CanvasSize,
  computeBoundingSphereFromThreeMesh,
  computeCentroidFromThreeMesh,
} from '@unpublished/four'
import { useMemo } from 'react'
import * as THREE from 'three'

import {
  useCanvas,
  useMainObject,
  useMainObjectGeometry,
} from '../canvas/canvas-context'

export function computeInitialView({
  mainObject,
  viewerSize,
  bodyPercentageToShow = 0.75,
}: {
  mainObject: THREE.Mesh
  viewerSize: CanvasSize
  bodyPercentageToShow?: number
}): CameraState {
  const center = computeCentroidFromThreeMesh(mainObject)

  const boundingSphere = computeBoundingSphereFromThreeMesh(mainObject)
  if (!boundingSphere) {
    throw Error('boundingSphere should be defined.')
  }

  const boundingSphereDiameter = boundingSphere.radius * 2

  return {
    position: [center.x, center.y, center.z + 2],
    target: center.toArray(),
    up: [0, 1, 0],
    // Compute zoom so that the bounding sphere exactly fills up the minimum
    // viewer dimension (height or width), and then multiply that by the given
    // percentage (to add a buffer around the object).
    zoom:
      (Math.min(viewerSize.height, viewerSize.width) / boundingSphereDiameter) *
      bodyPercentageToShow,
  }
}

export function useComputeInitialView(
  defaultToView?: CameraState
): CameraState | undefined {
  const mainObject = useMainObject()
  const mainObjectGeometry = useMainObjectGeometry()
  const canvas = useCanvas()

  // TODO: Try to rework this so it responds to changes to the main object
  // transform.

  // const [sentinel, setSentinel] = useState<Object>({})
  // const previousMatrixWorld = useRef<THREE.Matrix4>()
  // if (previousMatrixWorld.current === undefined) {
  //   previousMatrixWorld.current = new THREE.Matrix4()
  // }

  // if (
  //   mainObject &&
  //   !previousMatrixWorld.current.equals(mainObject.matrixWorld)
  // ) {
  //   console.log('matrices differ')
  //   previousMatrixWorld.current.copy(mainObject.matrixWorld)
  //   setSentinel({})
  // }

  const overrideView = useMemo(() => {
    if (mainObject && mainObjectGeometry && canvas) {
      return computeInitialView({
        mainObject,
        viewerSize: canvas.getSize(),
      })
    }
  }, [mainObject, canvas, mainObjectGeometry])

  return defaultToView ?? overrideView
}
