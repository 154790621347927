import { Vector3 } from 'polliwog-types'

export interface LandmarkingPageData<LandmarkId extends string, View> {
  landmarkNames: LandmarkId[]
  image?: string
  ordering?: 'ulnar'
  kind: 'pre' | 'post'
  referenceLandmarkNames?: LandmarkId[]
  title: string
  description: string
  help: string
  initialView: View
}

export type LandmarkingWorkflowData<
  LandmarkId extends string,
  View
> = LandmarkingPageData<LandmarkId, View>[]

export class LandmarkingPage<LandmarkId extends string, View> {
  readonly props: LandmarkingPageData<LandmarkId, View>

  constructor(props: LandmarkingPageData<LandmarkId, View>) {
    this.props = props
  }

  landmarksAreComplete(
    landmarks: Partial<Record<LandmarkId, Vector3>>
  ): boolean {
    return this.props.landmarkNames.every(name => landmarks[name] !== undefined)
  }

  get landmarkNames(): LandmarkId[] {
    return this.props.landmarkNames
  }
}

export interface NavigationChoices {
  hasNext: boolean
  hasPrevious: boolean
}

export class LandmarkingWorkflow<LandmarkId extends string, View> {
  readonly pages: LandmarkingPage<LandmarkId, View>[]
  readonly numPages: number
  readonly allLandmarkNames: LandmarkId[]
  readonly numLandmarks: number

  constructor(workflowData: LandmarkingWorkflowData<LandmarkId, View>) {
    this.pages = workflowData.map(pageData => new LandmarkingPage(pageData))
    this.numPages = this.pages.length
    this.allLandmarkNames = this.pages.flatMap(item => item.props.landmarkNames)
    this.numLandmarks = this.allLandmarkNames.length
  }

  page(pageNumber: number): LandmarkingPage<LandmarkId, View> {
    const result = this.pages[pageNumber - 1]
    if (!result) {
      throw Error(
        `Page number ${pageNumber} out of range, expected 1 to ${this.numPages}`
      )
    }
    return result
  }

  navigationChoicesForPage(pageNumber: number): NavigationChoices {
    if (pageNumber < 1 || pageNumber > this.numPages) {
      throw Error(`Page number ${pageNumber} is out of range`)
    }

    return {
      hasPrevious: pageNumber > 1,
      hasNext: pageNumber < this.numPages,
    }
  }

  landmarksAreComplete(
    landmarks: Partial<Record<LandmarkId, Vector3>>
  ): boolean {
    return this.pages.every(page => page.landmarksAreComplete(landmarks))
  }
}
