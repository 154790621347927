import { ValueType } from '@curvewise/armscyence-types-measure-hand'
import { DependencyGraph } from 'werkit'

import _handDependencyGraph from './dependencyGraph.json'

export type HandDependencyGraph = DependencyGraph<ValueType>

if (_handDependencyGraph.schemaVersion !== 1) {
  throw new Error('Expected _handDependencyGraph.schemaVersion to equal 1')
}

export const HAND_DEPENDENCY_GRAPH = _handDependencyGraph as HandDependencyGraph
