import { HandOrientation } from '@curvewise/common-types'
import { Point, Vector } from 'hyla'

import { HandLandmarks } from './hand-landmarks'

export const INITIAL_HAND_ORIENTATION: HandOrientation = {
  distal: [0, 1, 0],
  radial: [-1, 0, 0],
  dorsal: [0, 0, 1],
}

export function computeHandOrientation({
  mcp_d_3,
  w_d,
  w_u,
  w_r,
  mcp_r_2,
}: Partial<HandLandmarks>): HandOrientation | undefined {
  if (mcp_d_3 && w_d && w_u && w_r && mcp_r_2) {
    const distal = new Point(mcp_d_3).subtractPoint(new Point(w_d)).normalized()

    // Radial: Perpendicular to the distal axis and to the plane normal defined
    // by w_u, w_r, and mcp_r_2.
    const first = new Point(w_u)
    const second = new Point(w_r)
    const third = new Point(mcp_r_2)
    const planeNormal = third
      .subtractPoint(first)
      .cross(third.subtractPoint(second))
      .normalized()
    const radial = distal.cross(planeNormal)

    const dorsal = distal.cross(radial)

    return {
      distal: distal.coords,
      radial: radial.coords,
      dorsal: dorsal.coords,
    }
  } else {
    return undefined
  }
}

export type HandView =
  | 'dorsum'
  | 'palm'
  | 'radius'
  | 'radiusSlightlyDorsal'
  | 'ulna'
  | 'tips'
  | 'ulnarDigits'
  | 'radialDigits'

export class HandAxisHelper {
  readonly distal: Vector
  readonly radial: Vector
  readonly dorsal: Vector

  constructor({ distal, radial, dorsal }: HandOrientation) {
    this.distal = new Vector(distal)
    this.radial = new Vector(radial)
    this.dorsal = new Vector(dorsal)
  }

  get proximal(): Vector {
    return this.distal.negated()
  }

  get palmar(): Vector {
    return this.dorsal.negated()
  }

  get ulnar(): Vector {
    return this.radial.negated()
  }

  getView(view: HandView): { superiorAxis: Vector; anteriorAxis: Vector } {
    switch (view) {
      case 'dorsum':
        return { superiorAxis: this.distal, anteriorAxis: this.dorsal }
      case 'palm':
        return { superiorAxis: this.distal, anteriorAxis: this.palmar }
      case 'radius':
        return { superiorAxis: this.distal, anteriorAxis: this.radial }
      case 'ulna':
        return { superiorAxis: this.distal, anteriorAxis: this.ulnar }
      case 'tips':
        return { superiorAxis: this.ulnar, anteriorAxis: this.distal }
      case 'radiusSlightlyDorsal':
        return {
          superiorAxis: this.distal,
          anteriorAxis: this.radial.add(this.dorsal.timesScalar(2 / 3)),
        }
      case 'ulnarDigits':
        return {
          superiorAxis: this.distal.add(this.dorsal.timesScalar(1 / 6)),
          anteriorAxis: this.dorsal.add(this.ulnar.timesScalar(4 / 5)),
        }
      case 'radialDigits':
        return {
          superiorAxis: this.distal.add(this.dorsal.timesScalar(1 / 6)),
          anteriorAxis: this.dorsal.add(this.radial.timesScalar(4 / 5)),
        }
    }
  }
}
