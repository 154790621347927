import { LengthUnits } from '@curvewise/common-types'
import { Point } from '@unpublished/scene'
import { Vector } from 'hyla'
import { Vector3 } from 'polliwog-types'
import { createContext, useMemo } from 'react'

import { SegmentHelperDebugInfo } from './segment-helper'

export class DebugContext {
  segmentHelperDebugInfo?: SegmentHelperDebugInfo
}

export const ReactDebugContext = createContext<DebugContext>(new DebugContext())

function subdivideRay({
  origin,
  unitDirection,
  length,
  numSubdivisions = 20,
}: {
  origin: Vector3
  unitDirection: Vector3
  length: number
  numSubdivisions?: number
}): Vector3[] {
  const step = length / numSubdivisions
  return Array.from(Array(numSubdivisions).keys()).map(i => {
    const offset = i * step
    return [
      origin[0] + offset * unitDirection[0],
      origin[1] + offset * unitDirection[1],
      origin[2] + offset * unitDirection[2],
    ]
  })
}

function subdivideSegment({
  first,
  second,
  numSubdivisions,
}: {
  first: Vector3
  second: Vector3
  numSubdivisions?: number
}): Vector3[] {
  const segmentVector = new Vector(second).subtract(new Vector(first))
  return subdivideRay({
    origin: first,
    unitDirection: segmentVector.normalized().coords,
    length: segmentVector.length,
    numSubdivisions,
  })
}

function Ray({
  origin,
  unitDirection,
  length = 25,
  numSubdivisions = 20,
  radiusCm,
  units,
}: {
  origin: Vector3
  unitDirection: Vector3
  length?: number
  numSubdivisions?: number
  radiusCm?: number
  units?: LengthUnits
}): JSX.Element {
  const points = useMemo<Vector3[]>(
    () => subdivideRay({ origin, unitDirection, length, numSubdivisions }),
    [origin, unitDirection, length, numSubdivisions]
  )

  return (
    <group>
      {points.map((point, index) => (
        <Point
          key={`${index}`}
          point={point}
          color={index === numSubdivisions - 1 ? 'red' : 'paleGreen'}
          radiusCm={radiusCm}
          units={units}
        />
      ))}
    </group>
  )
}

function Segment({
  first,
  second,
  numSubdivisions = 20,
  radiusCm,
  units,
}: {
  first: Vector3
  second: Vector3
  numSubdivisions?: number
  radiusCm?: number
  units?: LengthUnits
}): JSX.Element {
  const points = useMemo<Vector3[]>(
    () => subdivideSegment({ first, second, numSubdivisions }),
    [first, second, numSubdivisions]
  )

  return (
    <group>
      {points.map((point, index) => (
        <Point
          key={`${index}`}
          point={point}
          color="paleGreen"
          radiusCm={radiusCm}
          units={units}
        />
      ))}
    </group>
  )
}

export function DebugSegmentHelper({
  debugInfo: {
    intersectionsMainObject,
    nearestIntersectionMainObject,
    unitNormalMainObject,
    segmentMainObject,
    disc,
  },
  pointRadiusCm,
  units,
}: {
  debugInfo: SegmentHelperDebugInfo
  pointRadiusCm?: number
  units?: LengthUnits
}): JSX.Element {
  const commonPointAttrs = { radiusCm: pointRadiusCm, units }

  return (
    <group>
      {intersectionsMainObject &&
        intersectionsMainObject.map((point, index) => (
          <Point
            key={`${index}`}
            point={point}
            color="green"
            {...commonPointAttrs}
          />
        ))}
      {nearestIntersectionMainObject && (
        <Point
          point={nearestIntersectionMainObject}
          color="darkGreen"
          {...commonPointAttrs}
        />
      )}
      {nearestIntersectionMainObject && unitNormalMainObject && (
        <Ray
          origin={nearestIntersectionMainObject}
          unitDirection={unitNormalMainObject}
          {...commonPointAttrs}
        />
      )}
      {segmentMainObject && (
        <Segment
          first={segmentMainObject.first.coords}
          second={segmentMainObject.second.coords}
          {...commonPointAttrs}
        />
      )}
      {disc && <Point point={disc.center} color="red" {...commonPointAttrs} />}
      {disc && (
        <Ray
          origin={disc.center}
          unitDirection={disc.unitNormal}
          {...commonPointAttrs}
        />
      )}
    </group>
  )
}
