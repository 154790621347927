import { useAuth0 } from '@auth0/auth0-react'
import { useCallback } from 'react'
import { WerkitInputMessage, WerkitOutputMessage } from 'werkit'

type MessageKeyType = null

export async function invokeLambda<InputType, ResultType>({
  gatewayUrl,
  getAccessTokenSilently,
  input,
}: {
  gatewayUrl: string
  getAccessTokenSilently: ReturnType<typeof useAuth0>['getAccessTokenSilently']
  input: InputType
}): Promise<WerkitOutputMessage<ResultType, MessageKeyType>> {
  const inputMessage: WerkitInputMessage<InputType, MessageKeyType> = {
    ...input,
    message_key: null,
  }
  const accessToken = await getAccessTokenSilently()

  const response = await fetch(gatewayUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    credentials: 'include',
    body: JSON.stringify(inputMessage),
  })
  const outputMessage =
    (await response.json()) as unknown as WerkitOutputMessage<
      ResultType,
      MessageKeyType
    >
  return outputMessage
}

export function useLambda<InputType, ResultType>(
  gatewayUrl: string
): (
  input: InputType
) => Promise<WerkitOutputMessage<ResultType, MessageKeyType>> {
  const { getAccessTokenSilently } = useAuth0()

  return useCallback(
    async (input: InputType) =>
      invokeLambda<InputType, ResultType>({
        gatewayUrl,
        getAccessTokenSilently,
        input,
      }),
    [gatewayUrl, getAccessTokenSilently]
  )
}
