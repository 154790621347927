import { HandOrientation } from '@curvewise/common-types'
import { PointsWithRenderDetails } from '@unpublished/scene'
import { Point, Vector } from 'hyla'
import { Vector3 } from 'polliwog-types'

export function createHandOrientationViewModel({
  orientation,
  dorsalApex,
}: {
  orientation: HandOrientation
  dorsalApex: Vector3
}): PointsWithRenderDetails {
  const dorsal = new Vector(orientation.dorsal)
  const radial = new Vector(orientation.radial)
  const distal = new Vector(orientation.distal)

  const origin = new Point(dorsalApex).addVector(dorsal.timesScalar(10))

  const axisLength = 25
  return {
    points: [
      { name: 'o', point: origin.coords, color: 'darkGray' },
      {
        name: 'radial',
        point: origin.addVector(radial.timesScalar(axisLength)).coords,
        color: 'red',
      },
      {
        name: 'dorsal',
        point: origin.addVector(dorsal.timesScalar(axisLength)).coords,
        color: 'green',
      },
      {
        name: 'distal',
        point: origin.addVector(distal.timesScalar(axisLength)).coords,
        color: 'blue',
      },
    ],
    showPointLabels: true,
  }
}
