import { ApolloClient, gql, useApolloClient } from '@apollo/client'
import { setDifference } from '@unpublished/victorinox'
import { useEffect } from 'react'

import {
  CreateDatasetAnnotationTypesMutation,
  CreateDatasetAnnotationTypesMutationVariables,
  GetDatasetAnnotationTypesQuery,
  GetDatasetAnnotationTypesQueryVariables,
} from '../../generated'

export async function getDatasetAnnotationSchema({
  apolloClient,
  datasetId,
}: {
  apolloClient: ApolloClient<object>
  datasetId: number
}): Promise<{ id: number; name: string }[]> {
  const { data } = await apolloClient.query<
    GetDatasetAnnotationTypesQuery,
    GetDatasetAnnotationTypesQueryVariables
  >({
    query: gql`
      query GetDatasetAnnotationTypes($datasetId: Int!) {
        datasetById(id: $datasetId) {
          geometryAnnotationTypesByDatasetId {
            nodes {
              id
              name
            }
          }
        }
      }
    `,
    variables: { datasetId },
  })
  if (!data) {
    throw Error(`Unable to load dataset annotations for dataset ${datasetId}`)
  }
  return data.datasetById.geometryAnnotationTypesByDatasetId.nodes
}

async function createDatasetAnnotations({
  apolloClient,
  datasetId,
  names,
}: {
  apolloClient: ApolloClient<object>
  datasetId: number
  names: string[]
}): Promise<void> {
  await apolloClient.mutate<
    CreateDatasetAnnotationTypesMutation,
    CreateDatasetAnnotationTypesMutationVariables
  >({
    mutation: gql`
      mutation CreateDatasetAnnotationTypes(
        $createInput: mnCreateGeometryAnnotationTypeInput!
      ) {
        mnCreateGeometryAnnotationType(input: $createInput) {
          __typename
        }
      }
    `,
    variables: {
      createInput: {
        mnGeometryAnnotationType: names.map(name => ({ name, datasetId })),
      },
    },
  })
}

async function ensureDatasetAnnotations({
  apolloClient,
  datasetId,
  names,
}: {
  apolloClient: ApolloClient<object>
  datasetId: number
  names: string[]
}): Promise<{ namesAdded: string[] }> {
  const existing = (
    await getDatasetAnnotationSchema({ apolloClient, datasetId })
  ).map(item => item.name)
  const missing = Array.from(setDifference(new Set(names), new Set(existing)))
  if (missing.length > 0) {
    await createDatasetAnnotations({ apolloClient, datasetId, names: missing })
  }
  return { namesAdded: missing }
}

export function useEnsureDatasetAnnotationSchema({
  datasetId,
  names,
}: {
  datasetId?: number
  names: string[]
}): void {
  const apolloClient = useApolloClient()

  useEffect(() => {
    async function ensure(datasetId: number): Promise<void> {
      await ensureDatasetAnnotations({ apolloClient, datasetId, names })
    }

    if (datasetId) {
      ensure(datasetId).catch(console.error)
    }
  }, [apolloClient, datasetId, names])
}
