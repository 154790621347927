import { Input, Output } from '@curvewise/armscyence-types-handy'
import { Side } from '@curvewise/common-types'
import { useEffect, useState } from 'react'

import { useLambda } from '../common/invoke-lambda'
import { useRefreshGeometryUrl } from '../common/refresh-geometry-url'

export function useHandyLambda({
  geometryId,
  side,
  endpointUrl,
}: {
  geometryId?: number
  side?: Side
  endpointUrl: string
}): Output | undefined {
  const [output, setOutput] = useState<Output>()

  const refreshGeometryUrl = useRefreshGeometryUrl()
  const invokeHandyLambda = useLambda<Input, Output>(endpointUrl)

  useEffect(() => {
    async function invoke({
      geometryId,
      side,
    }: {
      geometryId: number
      side: Side
    }): Promise<void> {
      const signedMeshUrl = await refreshGeometryUrl(geometryId)
      const outputMessage = await invokeHandyLambda({
        signedMeshUrl,
        side,
      })

      if (outputMessage.success) {
        setOutput(outputMessage.result)
      } else {
        console.error('lambda error', outputMessage.error)
      }
    }

    if (geometryId && side) {
      setOutput(undefined)
      invoke({ geometryId, side }).catch(console.error)
    } else {
      setOutput(undefined)
    }
  }, [geometryId, side, refreshGeometryUrl, invokeHandyLambda])

  return output
}
