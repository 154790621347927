import {
  AnnotationDelta,
  pickedPointReducer,
} from '@unpublished/common-components'
import { Point, Vector } from 'hyla'
import { Vector3 } from 'polliwog-types'

import { LandmarkingWorkflow } from './landmarking-workflow'

function scalarProjection(vector: Vector, onto: Vector): number {
  return vector.dot(onto.normalized())
}

function sortAlongAxis(points: Point[], axis: Vector): Point[] {
  return points
    .map(point => ({
      point,
      onAxis: scalarProjection(new Vector(point.coords), axis),
    }))
    .sort((first, second) => first.onAxis - second.onAxis)
    .map(item => item.point)
}

export interface State<LandmarkId extends string, View> {
  workflow: LandmarkingWorkflow<LandmarkId, View>
  landmarks: Partial<Record<LandmarkId, Vector3>>
  deltas: AnnotationDelta<LandmarkId>[]
  currentPage: number
  pageIsComplete: boolean
  unassignedLandmarks: Vector3[]
  isFinished: boolean
  clearingPoint?: LandmarkId
  clearingUnassignedPoint?: number
}

export function createInitialState<LandmarkId extends string, View>(
  workflow: LandmarkingWorkflow<LandmarkId, View>
): State<LandmarkId, View> {
  return {
    workflow,
    currentPage: 1,
    pageIsComplete: false,
    landmarks: {},
    deltas: [],
    unassignedLandmarks: [],
    isFinished: false,
  }
}

export type Action<LandmarkId extends string, View> =
  | { type: 'set-workflow'; workflow: LandmarkingWorkflow<LandmarkId, View> }
  | { type: 'load'; landmarks: Partial<Record<LandmarkId, Vector3>> }
  | { type: 'set-point'; point: Vector3; radial?: Vector3 }
  | { type: 'clear-point'; index: number }
  | { type: 'preview-clear-point'; index: number }
  | { type: 'cancel-preview-clear-point' }
  | { type: 'go-to-next-page' }
  | { type: 'go-to-previous-page' }
  | { type: 'finish' }
  | { type: 'restart' }
  | {
      type: 'discard-saved-deltas'
      deltas: AnnotationDelta<LandmarkId>[]
    }

function reducePageIsComplete<LandmarkId extends string, View>(
  state: State<LandmarkId, View>
): State<LandmarkId, View> {
  const { landmarkNames } = state.workflow.page(state.currentPage)
  return {
    ...state,
    pageIsComplete: landmarkNames.every(
      name => state.landmarks[name] !== undefined
    ),
  }
}

export function reducer<LandmarkId extends string, View>(
  state: State<LandmarkId, View>,
  action: Action<LandmarkId, View>
): State<LandmarkId, View> {
  if (action.type === 'load') {
    return reducePageIsComplete({
      ...createInitialState(state.workflow),
      landmarks: action.landmarks,
    })
  } else if (action.type === 'set-point') {
    const { landmarkNames } = state.workflow.page(state.currentPage)
    if (landmarkNames.length === 1) {
      const [landmarkName] = landmarkNames
      return {
        ...state,
        landmarks: { ...state.landmarks, [landmarkName]: action.point },
        deltas: pickedPointReducer(state.deltas, {
          type: 'update-delta',
          point: action.point,
          id: landmarkName,
        }),
        pageIsComplete: true,
      }
    } else if (state.pageIsComplete) {
      return state
    } else {
      const unassignedLandmarks = [...state.unassignedLandmarks, action.point]

      if (unassignedLandmarks.length < landmarkNames.length) {
        return { ...state, unassignedLandmarks }
      } else {
        if (state.workflow.page(state.currentPage).props.ordering !== 'ulnar') {
          throw Error('Only ulnar ordering is supported')
        }
        if (!action.radial) {
          throw Error('Expected radial vector to be provided')
        }
        const orderedPoints = sortAlongAxis(
          unassignedLandmarks.map(coords => new Point(coords)),
          new Vector(action.radial).negated()
        ).map(p => p.coords)
        const newLandmarks = { ...state.landmarks }
        let deltas = state.deltas
        for (let i = 0; i < landmarkNames.length; ++i) {
          const landmarkName = landmarkNames[i]
          const point = orderedPoints[i]
          newLandmarks[landmarkName] = point
          deltas = pickedPointReducer(deltas, {
            type: 'update-delta',
            point,
            id: landmarkName,
          })
        }
        return {
          ...state,
          landmarks: newLandmarks,
          deltas,
          unassignedLandmarks: [],
          pageIsComplete: true,
        }
      }
    }
  } else if (action.type === 'clear-point') {
    const { landmarkNames } = state.workflow.page(state.currentPage)
    if (landmarkNames.length === 1) {
      const [landmarkName] = landmarkNames
      return {
        ...state,
        landmarks: { ...state.landmarks, [landmarkName]: undefined },
        deltas: pickedPointReducer(state.deltas, {
          type: 'update-delta',
          point: null,
          id: landmarkName,
        }),
        pageIsComplete: false,
        clearingPoint: undefined,
      }
    } else if (state.unassignedLandmarks.length > 0) {
      const unassignedLandmarks = [...state.unassignedLandmarks]
      unassignedLandmarks.splice(action.index, 1)
      return {
        ...state,
        unassignedLandmarks,
        clearingUnassignedPoint: undefined,
      }
    } else {
      const landmarks = { ...state.landmarks }
      landmarks[landmarkNames[action.index]] = undefined
      const unassignedLandmarks: Vector3[] = []
      let deltas = state.deltas
      for (const name of landmarkNames) {
        deltas = pickedPointReducer(deltas, {
          type: 'update-delta',
          point: null,
          id: name,
        })
        const landmarkValue = landmarks[name]
        if (landmarkValue) {
          unassignedLandmarks.push(landmarkValue)
          landmarks[name] = undefined
        }
      }
      return {
        ...state,
        landmarks,
        deltas,
        unassignedLandmarks,
        pageIsComplete: false,
        clearingPoint: undefined,
      }
    }
  } else if (action.type === 'preview-clear-point') {
    if (state.unassignedLandmarks.length > 0) {
      return { ...state, clearingUnassignedPoint: action.index }
    } else {
      const { landmarkNames } = state.workflow.page(state.currentPage)
      return { ...state, clearingPoint: landmarkNames[action.index] }
    }
  } else if (action.type === 'cancel-preview-clear-point') {
    return {
      ...state,
      clearingPoint: undefined,
      clearingUnassignedPoint: undefined,
    }
  } else if (action.type === 'go-to-next-page') {
    if (state.workflow.navigationChoicesForPage(state.currentPage).hasNext) {
      return reducePageIsComplete({
        ...state,
        currentPage: state.currentPage + 1,
      })
    }
  } else if (action.type === 'go-to-previous-page') {
    if (
      state.workflow.navigationChoicesForPage(state.currentPage).hasPrevious
    ) {
      return reducePageIsComplete({
        ...state,
        currentPage: state.currentPage - 1,
      })
    }
  } else if (action.type === 'finish') {
    if (!state.workflow.landmarksAreComplete(state.landmarks)) {
      throw Error('Expected all landmarks to be filled in before finishing')
    }
    if (state.unassignedLandmarks.length > 0) {
      throw Error('Exepected all landmarks to be assigned before finishing')
    }
    return { ...state, isFinished: true }
  } else if (action.type === 'restart') {
    return reducePageIsComplete({
      ...state,
      currentPage: 1,
      isFinished: false,
      clearingPoint: undefined,
      clearingUnassignedPoint: undefined,
      unassignedLandmarks: [],
    })
  } else if (action.type === 'set-workflow') {
    return reducer({ ...state, workflow: action.workflow }, { type: 'restart' })
  } else if (action.type === 'discard-saved-deltas') {
    return {
      ...state,
      deltas: pickedPointReducer(state.deltas, action),
    }
  }

  return state
}
