import Ajv from 'ajv'

import { MeasuredHandDocument } from './measured-hand-document.schema'

const ajv = new Ajv().addSchema(
  require('./generated/measured-hand-document.schema.json')
)

export function isMeasuredHandDocument(
  data: any
): data is MeasuredHandDocument {
  return ajv.validate('#/definitions/MeasuredHandDocument', data) as boolean
}

export function assertMeasuredHandDocument(data: any): void {
  if (!isMeasuredHandDocument(data)) {
    throw Error(ajv.errorsText(ajv.errors))
  }
}
