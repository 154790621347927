import React, { useReducer } from 'react'

import { LandmarkingWorkflow } from './landmarking-workflow'
import { LandmarkingWorkflowPanel } from './landmarking-workflow-panel'
import { Action, createInitialState, reducer, State } from './state'
import { createViewModel, ViewModel } from './view-model'

export interface UseLandmarkingWorkflow<LandmarkId extends string, View> {
  renderPanel: () => JSX.Element
  viewModel: ViewModel<LandmarkId, View>
  state: State<LandmarkId, View>
  dispatch: React.Dispatch<Action<LandmarkId, View>>
}

export function useLandmarkingWorkflow<LandmarkId extends string, View>({
  initialWorkflow,
}: {
  initialWorkflow: LandmarkingWorkflow<LandmarkId, View>
}): UseLandmarkingWorkflow<LandmarkId, View> {
  const [state, dispatch] = useReducer(
    reducer,
    createInitialState(initialWorkflow)
  )

  const viewModel = createViewModel(state)

  function renderPanel(): JSX.Element {
    return (
      <LandmarkingWorkflowPanel viewModel={viewModel} dispatch={dispatch} />
    )
  }

  return {
    renderPanel,
    viewModel: viewModel as ViewModel<LandmarkId, View>,
    state: state as State<LandmarkId, View>,
    dispatch,
  }
}
