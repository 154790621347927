import { gql, useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { UserProfile, WhenUnauthenticated } from '@unpublished/auth0-helpers'
import { Button, Dropzone, DropzoneState } from '@unpublished/common-components'
import { readJsonFromFile } from '@unpublished/victorinox'
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import Select from 'react-select'
import styled from 'styled-components'

import { assertMeasuredHandDocument } from '../app/measurement-workflow/measured-hand-document'
import {
  GoldieMeshInfo,
  MeasuredHandDocument,
} from '../app/measurement-workflow/measured-hand-document.schema'
import { findGoldieMeshInfo } from '../app/measurement-workflow/measured-hand-document-helpers'
import { ListHandDatasetsQuery } from '../generated'

export const HAND_DATASET_QUERY = gql`
  query ListHandDatasets {
    allDatasets(orderBy: LAST_MODIFIED_DESC, condition: { bodyPart: HAND }) {
      nodes {
        id
        name
        subjectsByDatasetId {
          edges {
            node {
              id
              name
              gender
              geometrySeriesBySubjectId {
                edges {
                  node {
                    id
                    poseTypeByPoseTypeId {
                      id
                      name
                    }
                    geometriesByGeometrySeriesId {
                      edges {
                        node {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const ButtonContainer = styled.div`
  button {
    margin: 0 1.5px;
  }
`

const PanelRow = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const Panel = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  > * {
    margin: 10px;
    min-width: 60%;
    max-width: 100%;
    overflow: hidden;
  }
`

async function loadMeasuredHand(
  file: File,
  { dataLayerUrl }: { dataLayerUrl: string }
): Promise<{
  measuredHand: MeasuredHandDocument
  goldieMeshInfo: GoldieMeshInfo
}> {
  const data = await readJsonFromFile(file)
  try {
    assertMeasuredHandDocument(data)
    const measuredHand = data as MeasuredHandDocument

    const goldieMeshInfo = findGoldieMeshInfo(data.mesh)
    if (!goldieMeshInfo) {
      throw Error('Expected mesh to come from Goldie')
    }
    if (goldieMeshInfo.dataLayerUrl !== dataLayerUrl) {
      throw Error(
        `App's configured Goldie URL is ${dataLayerUrl}, but got ${goldieMeshInfo.dataLayerUrl}`
      )
    }

    return { measuredHand, goldieMeshInfo }
  } catch (e) {
    // TODO: Present an error message.
    console.error(e)
    throw Error('Data is not in an accepted format')
  }
}

interface SelectOption<T> {
  value: T
  label: string
}

interface ViewModel {
  datasetChoices: SelectOption<number>[]
  subjectChoices: SelectOption<number>[]
  geometryChoices: SelectOption<number>[]
}

function getViewModel({
  datasetQuery,
  selectedDatasetId,
  selectedSubjectId,
}: {
  datasetQuery?: ListHandDatasetsQuery
  selectedDatasetId?: number
  selectedSubjectId?: number
}): ViewModel {
  return {
    datasetChoices:
      datasetQuery?.allDatasets.nodes.map(node => ({
        value: node.id,
        label: node.name,
      })) ?? [],
    subjectChoices:
      datasetQuery?.allDatasets.nodes
        .filter(node => node.id === selectedDatasetId)
        .flatMap(node =>
          node.subjectsByDatasetId.edges.map(subject => ({
            value: subject.node.id,
            label: subject.node.name,
          }))
        ) ?? [],
    geometryChoices:
      datasetQuery?.allDatasets.nodes
        .filter(node => node.id === selectedDatasetId)
        .flatMap(node => node.subjectsByDatasetId.edges)
        .filter(edge => edge.node.id === selectedSubjectId)
        .flatMap(edge =>
          edge.node.geometrySeriesBySubjectId.edges.flatMap(geometrySeries =>
            geometrySeries.node.geometriesByGeometrySeriesId.edges.flatMap(
              geometry => ({
                value: geometry.node.id,
                label: geometrySeries.node.poseTypeByPoseTypeId.name,
              })
            )
          )
        ) ?? [],
  }
}

export type HandleLoadFn = (
  selectedGeometryId: number,
  document?: MeasuredHandDocument
) => void

export function Opener({
  authEnabled,
  dataLayerUrl,
  isOpen,
  handleLoad,
  onRequestClose,
}: {
  authEnabled: boolean
  dataLayerUrl: string
  isOpen: boolean
  handleLoad: HandleLoadFn
  onRequestClose: () => void
}): JSX.Element {
  const [dropzoneState, setDropzoneState] = useState<DropzoneState>({})
  const [isLoading, setIsLoading] = useState(false)
  const { data, error } = useQuery<ListHandDatasetsQuery, {}>(
    HAND_DATASET_QUERY
  )
  const [selectedDatasetId, setSelectedDatasetId] = useState<number>()
  const [selectedSubjectId, setSelectedSubjectId] = useState<number>()
  const [selectedGeometryId, setSelectedGeometryId] = useState<number>()

  const canOpen = !isLoading && Boolean(dropzoneState.measurements)
  const { isAuthenticated } = useAuth0()
  const shouldShowQueryError = !authEnabled || isAuthenticated

  const { datasetChoices, subjectChoices, geometryChoices } = getViewModel({
    datasetQuery: data,
    selectedDatasetId,
    selectedSubjectId,
  })

  // TODO: Maybe disable this defaulting behavior in production?
  useEffect(() => {
    if (!selectedDatasetId && datasetChoices.length) {
      const datasetId = datasetChoices[0].value
      const { subjectChoices } = getViewModel({
        datasetQuery: data,
        selectedDatasetId: datasetId,
      })
      if (subjectChoices.length) {
        const subjectId = subjectChoices[0].value
        const { geometryChoices } = getViewModel({
          datasetQuery: data,
          selectedDatasetId: datasetId,
          selectedSubjectId: subjectId,
        })
        if (geometryChoices.length) {
          setSelectedDatasetId(datasetId)
          setSelectedSubjectId(subjectId)
          setSelectedGeometryId(geometryChoices[0].value)
        }
      }
    }
  }, [data, selectedDatasetId, datasetChoices])

  async function handleOpenMeasurements(): Promise<void> {
    setIsLoading(true)
    const measuredHandFile = dropzoneState.measurements
    if (!measuredHandFile) {
      throw Error('How did we get here?')
    }
    try {
      const { measuredHand, goldieMeshInfo } = await loadMeasuredHand(
        measuredHandFile,
        { dataLayerUrl }
      )
      handleLoad(goldieMeshInfo.geometryId, measuredHand)
      console.log('loaded measured hand', measuredHand)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      ariaHideApp={false}
      contentLabel="Open"
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      style={{ overlay: { zIndex: 10 } }}
    >
      <PanelRow>
        <Panel>
          <h2>Load existing measured hand</h2>
          <Dropzone
            enableMeasurements
            measurementLabel="Measured hand"
            onChange={setDropzoneState}
          />
          <ButtonContainer>
            <button onClick={() => onRequestClose()}>Cancel</button>
            <button disabled={!canOpen} onClick={handleOpenMeasurements}>
              Open
            </button>
          </ButtonContainer>
        </Panel>
        <Panel>
          <h2>New measured hand</h2>
          {error && shouldShowQueryError && <p>Oh no! {error.message}</p>}
          <label htmlFor="dataset">Dataset</label>
          <Select
            name="dataset"
            onChange={(option: any, { action }) => {
              if (option && action === 'select-option') {
                setSelectedDatasetId(option.value)
              }
            }}
            value={
              datasetChoices.find(
                option => option.value === selectedDatasetId
              ) ?? null
            }
            options={datasetChoices}
            // Make sure the popup menu appears in front of the modal.
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 10 }) }}
          />
          <label htmlFor="subject">Subject</label>
          <Select
            name="subject"
            isDisabled={selectedDatasetId === undefined}
            onChange={(option: any, { action }) => {
              if (option && action === 'select-option') {
                setSelectedSubjectId(option.value)
              }
            }}
            value={
              subjectChoices.find(
                option => option.value === selectedSubjectId
              ) ?? null
            }
            options={subjectChoices}
            // Make sure the popup menu appears in front of the modal.
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 10 }) }}
          />
          <label htmlFor="geometry">Geometry</label>
          <Select
            name="geometry"
            isDisabled={selectedSubjectId === undefined}
            onChange={(option: any, { action }) => {
              if (option && action === 'select-option') {
                setSelectedGeometryId(option.value)
              }
            }}
            value={
              geometryChoices.find(
                option => option.value === selectedGeometryId
              ) ?? null
            }
            options={geometryChoices}
            // Make sure the popup menu appears in front of the modal.
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 10 }) }}
          />
          <Button
            onClick={() => handleLoad(selectedGeometryId as number)}
            disabled={selectedGeometryId === undefined}
          >
            Pick geometry
          </Button>
        </Panel>
        <Panel>
          {authEnabled && <UserProfile />}
          <WhenUnauthenticated>Please log in.</WhenUnauthenticated>
        </Panel>
      </PanelRow>
    </Modal>
  )
}
